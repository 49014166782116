import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'lib/components/Button';
import FormField from 'lib/components/FormField';
import { useFormSave } from 'lib/hooks/useFormSave';

import styles from 'lib/components/PspComposer/PspComposer.module.scss';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents a form used for Colombia including a list of Bank from Colombia, the bank account number,
 * and some information related to Colombia. It is only used to collect refund request data.
 */
const ColombianForm = React.forwardRef(
    (
        {
            pspId,
            token,
            paymentRequestId,
            lyriaUrl,
            hidePayButton,
            labelPayButton,
            onPaymentResult,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const formRef = React.useRef(null);
        const [formData, setFormData] = React.useState({
            bankAccountType: '',
            bankAccountNumber: '',
            bankAccountHolderName: '',
            bankName: '',
            documentNumber: '',
            documentType: '',
        });
        const [errors, setErrors] = React.useState({});

        const handleSuccess = React.useCallback(
            (response) => {
                onPaymentResult({ success: true, data: response });
            },
            [onPaymentResult],
        );

        const handleError = React.useCallback(
            (response) => {
                if (response?.data) {
                    const fieldErrors = {};
                    Object
                        .keys(response.data)
                        .forEach((key) => {
                            fieldErrors[key] = response.data[key] && response.data[key].map((error) => t([
                                `colombian_form_${key}_error_${error.code}`,
                                `error_${error.code}`,
                                'error_form',
                            ]));
                        });
                    setErrors(fieldErrors);
                }
                onPaymentResult({
                    success: false,
                    data: response,
                });
            },
            [onPaymentResult, t],
        );

        const url = lyriaUrl + '/colombian-form/save';
        const saveForm = useFormSave(url, token, paymentRequestId, pspId, handleSuccess, handleError);

        const submitForm = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                const data = {
                    bank_account_holder_name: formData.bankAccountHolderName,
                    bank_account_number: formData.bankAccountNumber,
                    bank_account_type: formData.bankAccountType,
                    bank_name: formData.bankName,
                    document_number: formData.documentNumber,
                    document_type: formData.documentType,
                };
                saveForm(data);
            },
            [formData, saveForm],
        );

        const onChangeFormField = React.useCallback(
            (fieldName) => (event, newValue) => {
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: newValue,
                }));

            },
            [],
        );

        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    return formRef.current.click();
                },
                canClickOnPay: Object.values(formData).every((value) => !!value),
            }),
            [formData],
        );

        return (
            <div id="wz-lyriapay__colombian-form" className={styles.common_psp}>
                <form noValidate onSubmit={submitForm}>
                    <div className={styles.common_psp_form}>
                        <FormField
                            name="bank_name"
                            value={formData?.bankName}
                            onChange={onChangeFormField('bankName')}
                            label={t('colombian_form_bank_name')}
                            errors={errors.bank_name}
                            required
                            type="select"
                            options={[
                                { value: '', label: '' },
                                { value: 'Bancolombia', label: 'Bancolombia' },
                                { value: 'Bancoomeva', label: 'Bancoomeva' },
                                { value: 'Banco Agrario', label: 'Banco Agrario' },
                                { value: 'Banco Av Villas', label: 'Banco Av Villas' },
                                { value: 'Banco BBVA', label: 'Banco BBVA' },
                                { value: 'Banco Caja Social', label: 'Banco Caja Social' },
                                { value: 'Banco Corpbanca', label: 'Banco Corpbanca' },
                                { value: 'Banco Davivienda', label: 'Banco Davivienda' },
                                { value: 'Banco Falabella', label: 'Banco Falabella' },
                                { value: 'Banco de Bogotá', label: 'Banco de Bogotá' },
                                { value: 'Banco de Occidente', label: 'Banco de Occidente' },
                                { value: 'Banco GNB Sudameris', label: 'Banco GNB Sudameris' },
                                { value: 'Banco Itaú', label: 'Banco Itaú' },
                                { value: 'Banco Pichincha', label: 'Banco Pichincha' },
                                { value: 'Banco Popular', label: 'Banco Popular' },
                                { value: 'Cooperativo Coopcentral', label: 'Cooperativo Coopcentral' },
                                { value: 'Iris', label: 'Iris' },
                                { value: 'Mundo Mujer', label: 'Mundo Mujer' },
                                { value: 'Nequi', label: 'Nequi' },
                                { value: 'Scotiabank Colpatria', label: 'Scotiabank Colpatria' },
                            ]}
                        />
                        <FormField
                            name="bank_account_number"
                            value={formData?.bankAccountNumber}
                            onChange={onChangeFormField('bankAccountNumber')}
                            label={t('colombian_form_bank_account_number')}
                            errors={errors.bank_account_number}
                            required
                        />
                        <FormField
                            name="bank_account_holder_name"
                            value={formData?.bankAccountHolderName}
                            onChange={onChangeFormField('bankAccountHolderName')}
                            label={t('colombian_form_bank_account_holder_name')}
                            errors={errors.bank_account_holder_name}
                            required
                        />
                        <FormField
                            name="bank_account_type"
                            value={formData?.bankAccountType}
                            onChange={onChangeFormField('bankAccountType')}
                            label={t('colombian_form_bank_account_type')}
                            errors={errors.bank_account_type}
                            required
                            type="select"
                            options={[
                                { value: '', label: '' },
                                { value: 'Ahorros', label: 'Ahorros' },
                                { value: 'Corriente', label: 'Corriente' },
                                { value: 'Daviplata', label: 'Daviplata' },
                            ]}
                        />
                        <FormField
                            name="document_type"
                            value={formData?.documentType}
                            onChange={onChangeFormField('documentType')}
                            label={t('colombian_form_document_type')}
                            errors={errors.document_type}
                            required
                            type="select"
                            options={[
                                { value: '', label: '' },
                                { value: 'Cédula de ciudadanía', label: t('colombian_form_document_type_type_citizen') },
                                { value: 'NIT', label: t('colombian_form_document_type_type_nit') },
                                { value: 'Cédula de extranjería', label: t('colombian_form_document_type_type_foreigner') },
                                { value: 'Passport', label: t('colombian_form_document_type_type_passport') },
                            ]}
                        />
                        <FormField
                            name="document_number"
                            value={formData?.documentNumber}
                            onChange={onChangeFormField('documentNumber')}
                            label={t('colombian_form_document_number')}
                            errors={errors.document_number}
                            required
                        />
                    </div>
                    {!hidePayButton && (
                        <div className={styles.common_psp_submit}>
                            <Button
                                disabled={Object.values(formData).some((value) => !value)}
                                label={labelPayButton || t('payButton')}
                                ref={formRef}
                                type='submit'
                            />
                        </div>
                    )}
                </form>
            </div>
        );
    },
);

ColombianForm.propTypes = {
    /**
     * The is of the PSP returned by the backend in order to be able to pass it when we submit the form data.
     */
    pspId: PropTypes.number.isRequired,
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string,
    /**
     * The paymentRequestID to use in order to be able to call the backend when we submit the data in the paymentPage.
     */
    paymentRequestId: PropTypes.string,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
};

ColombianForm.defaultProps = {
    hidePayButton: false,
    labelPayButton: null,  // Fallback on 'pay' or 'ask refund' label.
};

ColombianForm.displayName = 'ColombianForm';

export default ColombianForm;
