import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormField from 'lib/components/FormField';
import './ExpiryInput.module.scss';

/**
 * The goal of this component is to provide an input to enter the expiry date of a credit card.
 * The expiry date should and will be formatted thanks to the rule 'MM/YY'.
 */
const ExpiryInput = ({
    defaultExpiryDate,
    onChange,
    disabled,
    forceDirty,

}) => {
    const { t } = useTranslation();

    const [textValue, setTextValue] = React.useState(defaultExpiryDate);
    const [isDirty, setIsDirty] = React.useState(false);
    const [isFocused, setIsFocused] = React.useState(false);
    const [isValid, setIsValid] = React.useState(true);

    const checkExpiryDateIsValid = React.useCallback(
        (month, year) => {
            const now = new Date();
            return (
                month && year && (
                    year > now.getFullYear() || (
                        year === now.getFullYear() && month >= now.getMonth() + 1
                    )
                )
            );
        },
        [],
    );

    const onChangeExpiryInput = React.useCallback(
        () => (event, newValue) => {
            setIsDirty(true);
            setTextValue(newValue);
        },
        [],
    );

    const formatAndValidateExpiry = React.useCallback(
        (previousValue) => (value) => {
            const expiryTextValue = value || '';
            const expiryParsedTextValue = expiryTextValue.split('/');
            const parsedMonth = parseInt(expiryParsedTextValue[0]);
            const parsedYear = parseInt(expiryParsedTextValue[1]);
            const month = 0 < parsedMonth && parsedMonth <= 12 ? parsedMonth : null;
            const year = 21 <= parsedYear && parsedYear <= 99 ? parsedYear + 2000 : null;
            const isExpiryValid = checkExpiryDateIsValid(month, year);

            setIsValid(isExpiryValid);

            switch (expiryTextValue.length) {
                case 0:
                    return '';
                case 1:
                    if (expiryTextValue.match(/^[0-1]$/g)) {
                        return expiryTextValue;
                    } else {
                        return previousValue;
                    }
                case 2:
                    if (expiryTextValue.match(/^(0[1-9]|1[0-2])$/g)) {
                        return previousValue.length === 3
                            ? expiryTextValue.substring(0, 1)
                            : `${expiryTextValue}/`;
                    } else {
                        return previousValue;
                    }
                case 3:
                    if (expiryTextValue.match(/^(0[1-9]|1[0-2])\/$/g)) {
                        return expiryTextValue;
                    } else {
                        return previousValue;
                    }
                case 4:
                    if (expiryTextValue.match(/^(0[1-9]|1[0-2])\/[0-9]$/g)) {
                        return expiryTextValue;
                    } else {
                        return previousValue;
                    }
                case 5:
                    if (expiryTextValue.match(/^(0[1-9]|1[0-2])\/([0-9]{2})$/g)) {
                        return expiryTextValue;
                    } else {
                        return previousValue;
                    }
                default:
                    return previousValue;
            }
        },
        [checkExpiryDateIsValid],
    );

    React.useEffect(
        () => {
            if (onChange) {
                onChange({
                    value: textValue,
                    isValid: isValid,
                });
            }
        },
        [isValid, textValue, onChange],
    );

    return (
        <div id="expiry_input">
            <FormField
                id='expirydate'
                name='exp-date'
                placeholder='MM/YY'
                label={t('expiryLabel')}
                autoComplete="cc-exp"
                pattern="\d*"
                required
                shouldDisplayAsteriskIfRequired={false}
                value={textValue}
                disabled={disabled}
                onBlur={() => setIsFocused(false)}
                onFocus={() => setIsFocused(true)}
                onChange={onChangeExpiryInput(textValue)}
                formatAndValidate={formatAndValidateExpiry(textValue)}
                errors={
                    (isDirty || forceDirty) && !isFocused
                        ? textValue
                            ? isValid
                                ? null
                                : [t('expiryInvalid')]
                            : [t('expiryEmpty')]
                        : null
                }
                inputProps={{
                    inputMode: 'numeric',
                }}
            />
        </div>
    );

};

ExpiryInput.propTypes = {
    /**
     * The default value to put in the input for the expiry date including month and year.
     * The format must be XX/XX following the regexp ^(0[1-9]|1[0-2])\/([0-9]{2})$
     */
    defaultExpiryDate: PropTypes.string,
    /**
     * The callback function to trigger when the input is changed.
     * The function takes the arguments: value ({ month, year }), isValid.
     */
    onChange: PropTypes.func,
    /**
     * Whether the input must be disabled (just in read only) or not.
     */
    disabled: PropTypes.bool,
    /**
     * Whether we want to set the input to be dirty (that is to say as it has already been touched by the user).
     * That's mainly for error display purpose.
     */
    forceDirty: PropTypes.bool,
};

ExpiryInput.defaultProps = {
    defaultExpiryDate: null,
    onChange: null,
    disabled: false,
    forceDirty: false,
};

export default ExpiryInput;
