import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'lib/components/Icon';
import styles from './PaymentsOSErrorLabel.module.scss';

const PaymentsOSErrorLabel = ({ errorContent, errorIcon }) => {
    if (!errorContent) {
        return null;
    }

    return (
        <div className={styles.label}>
            <div className={styles.icon}>
                {errorIcon === 'alert' && (<Icon id="alert" />)}
                {errorIcon === 'cross' && (<Icon id="cross" />)}
            </div>
            <div className={styles.content}>
                {errorContent}
            </div>
        </div>
    );
};

PaymentsOSErrorLabel.propTypes = {
    /**
     * The error content to display.
     */
    errorContent: PropTypes.node,
    /**
     * The error icon to display on the left.
     */
    errorIcon: PropTypes.oneOf(['alert', 'cross']),
};

PaymentsOSErrorLabel.defaultProps = {
    errorContent: null,
    errorIcon: 'alert',
};

PaymentsOSErrorLabel.displayName = 'PaymentsOSErrorLabel';

export default PaymentsOSErrorLabel;
