import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PaymentsOSErrorLabel from 'lib/components/PaymentsOS/components/PaymentsOSErrorLabel/PaymentsOSErrorLabel';

/**
 * This component is the Payments OS Cvx form input for a given card.
 */
const PaymentsOSCvxInput = React.forwardRef(
    (
        {
            config,
            savedPaymentMethod,
            onResult,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const [errorCvv, setErrorCvv] = React.useState(undefined);

        const create = React.useCallback(
            () => {
                // Pass the public key
                window.POSC.setPublicKey(config.public_key);
                // Set the environment
                window.POSC.setEnvironment(config.production ? 'live' : 'test');
                // Pass the card's token
                window.POSC.setPaymentToken(savedPaymentMethod.token);
                // Set a placeholder
                window.POSC.setSecurityNumberPlaceholder('123');
                // Set style
                window.POSC.setStyle({
                    base: {
                        height: '16px',
                    },
                });
                // Initialize the form's field
                window.POSC.initCvvEncryptor('cvv');

            },
            [config, savedPaymentMethod],
        );

        React.useEffect(
            () => {
                const script = window.document.createElement('script');
                script.src = 'https://js.paymentsos.com/cvv/latest/cvv-encryptor.min.js';
                script.onload = create;
                window.document.body.appendChild(script);
                return () => window.document.body.removeChild(script);
            },
            [create],
        );

        // The handler to be able to external component to trigger 'tokenize' and hasCvvError actions.
        React.useImperativeHandle(
            ref,
            () => ({
                tokenize() {
                    try {
                        return window.POSC.createTokenById(
                            'cvv',
                            (result) => {
                                onResult(result);
                            },
                        );
                    } catch(error) {
                        console.info(error);
                    }
                },
                hasCvvError() {
                    setErrorCvv('error');
                },
            }),
            [onResult],
        );

        return (
            <div id="wz-lyriapay__payments-os-cvx-input">
                <div className="wz-lyriapay__payments-os-card-input__row wz-lyriapay__payments-os-card-input__row--external-field">
                    <label htmlFor="cvv">{t('cvcLabel')}</label>
                    <div id="cvv" className="wz-lyriapay__payments-os-card-input__row--external-field__wrapper" />
                    <PaymentsOSErrorLabel errorContent={errorCvv ? t('cvcInvalid') : null} />
                </div>
            </div>
        );
    },
);

PaymentsOSCvxInput.propTypes = {
    /**
     * The specific configuration related to this payment method (Payments OS).
     */
    config: PropTypes.object,
    /**
     * The saved payment method for which we want to display the cvx inputt.
     */
    savedPaymentMethod: PropTypes.object,
    /**
     * The external callback to trigger when the tokenization of the cvx is processed.
     */
    onResult: PropTypes.func,
};

PaymentsOSCvxInput.displayName = 'PaymentsOSCvxInput';

export default PaymentsOSCvxInput;
