/**
 * Return the object without the entries for which the value is included in the forbidden values.
 * @param {Object} obj The object to filter / clean.
 * @param {Array.<*|null|undefined>} valuesToClean The forbidden values.
 * @return {Object} The filtered / clean object.
 */
export const cleanObjectOnValues = (obj, valuesToClean) => {
    if (typeof obj !== 'object' || !valuesToClean) {
        return obj;
    }
    return Object.fromEntries(Object.entries(obj).filter((entry) => !valuesToClean.includes(entry[1])));
};
