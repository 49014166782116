import React from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import ApplePayButton from 'lib/components/ApplePayButton';
import Button from 'lib/components/Button';
import ButtonLink from 'lib/components/ButtonLink';
import CardInput from 'lib/components/CardInput';
import CvxInput from 'lib/components/CardInput/components/CvxInput';
import GooglePayButton from 'lib/components/GooglePayButton';
import ExpiryInput from 'lib/components/CardInput/components/ExpiryInput';
import Icon from 'lib/components/Icon';
import InfoBlock from 'lib/components/InfoBlock';
import SavedCardInput from 'lib/components/SavedCardInput';
import WaitingBlock from 'lib/components/WaitingBlock';
import { getClientCapabilities } from 'lib/utils/browserUtils';
import { findTokenizer, formatExpiryDate } from 'lib/utils/cardUtils';
import styles from './Payline.module.scss';
import styles_psp from 'lib/components/PspComposer/PspComposer.module.scss';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents the Payline PSP integration that can deal with multiple bank card types.
 */
const Payline = React.forwardRef(
    (
        {
            config,
            token,
            paymentRequestId,
            lyriaUrl,
            onPaymentResult,
            onPaymentStarted,
            hidePayButton,
            labelPayButton,
            labelPayButtonApplePayAndGooglePay,
            loader,
            savePaymentMethod,
            savedPaymentMethods,
            paymentCardInputWarningMessage,
            operation,
            lang,
            themeMode,
            onPaymentError,
            isDisabled,
        },
        ref,
    ) => {
        /* ------------ Initialization of the imports, the variables and the states ------------ */

        // Hooks
        const { t } = useTranslation();

        const iframe3ds = React.useRef(null);
        const iframe3dsmethod = React.useRef(null);
        const [attempts, setAttempts] = React.useState(0);
        // Display behavior states
        const [isIframeShown, showIframe] = React.useState(false);
        const [isFormVisible, showForm] = React.useState(true);
        const [isSpinnerVisible, showSpinner] = React.useState(false);
        const [displayDoNotLeaveWarning, setDisplayDoNotLeaveWarning] = React.useState(false);
        const [isReadOnly, setReadOnly] = React.useState(false);
        const [submitTried, setSubmitTried] = React.useState(false);
        // Payment method fields and fields related states
        const [card, setCard] = React.useState({
            value: '',
            isValid: false,
            cardTypeConfig: null,
        });
        const [expiry, setExpiry] = React.useState({
            value: '',
            isValid: false,
        });
        const [cvc, setCvc] = React.useState({
            value: '',
            isValid: false,
        });
        const [savePaymentMethodValue, setSavePaymentMethodValue] = React.useState(false);
        const [selectedPaymentMethodUuid, setSelectedPaymentMethodUuid] = React.useState(null);
        const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null);

        /* ------------ The main functions ------------ */

        // The function to trigger when Payline response is successful (payment ok).
        const handleSuccess = React.useCallback(
            (response) => {
                onPaymentResult({ success: true, data: response });
                showForm(false);
                showIframe(false);
                showSpinner(false);
                setDisplayDoNotLeaveWarning(false);
            },
            [onPaymentResult],
        );

        // The function to trigger when Payline response is not successful (payment ko).
        const handleError = React.useCallback(
            (response) => {
                setAttempts((attempts) => attempts + 1);
                showSpinner(false);
                setSubmitTried(false);
                setReadOnly(false);
                showForm(true);
                showIframe(false);
                setDisplayDoNotLeaveWarning(false);
                onPaymentResult({ success: false, data: response });
                onPaymentError(response);
            },
            [onPaymentResult, onPaymentError],
        );

        // The function to check the enrollment of the card when 3DS method happens.
        const verifyEnrollment = React.useCallback(
            (verifyParameters) => {
                showForm(false);
                setDisplayDoNotLeaveWarning(true);
                let iframe;
                if (verifyParameters.code === '03100') {
                    iframe = iframe3dsmethod.current;
                    showSpinner(true);
                    showIframe(false);
                } else {
                    iframe = iframe3ds.current;
                    showIframe(true);
                }

                if (!iframe) {
                    // Avoid sentry noise: but to investigate why the reference is null (maybe a rerender)
                    console.error('Impossible to retrieve iframe');
                    return;
                }
                const iframeContent = iframe.contentDocument;
                let form = iframeContent.createElement('form');
                form.setAttribute('method', verifyParameters.method);
                form.setAttribute('action', verifyParameters.url);
                let charsetField = iframeContent.createElement('input');
                charsetField.setAttribute('name', '_charset_');
                charsetField.setAttribute('type', 'hidden');
                charsetField.setAttribute('value', 'UTF-8');
                form.appendChild(charsetField);
                Object.keys(verifyParameters.data).forEach((key) => {
                    let hiddenField = iframeContent.createElement('input');
                    hiddenField.setAttribute('name', key);
                    hiddenField.setAttribute('type', 'hidden');
                    hiddenField.setAttribute('value', verifyParameters.data[key]);
                    form.appendChild(hiddenField);
                });
                iframeContent.documentElement.appendChild(form);
                form.submit();
                if (verifyParameters.code !== '03100') {
                    showSpinner(false);
                    iframe.style.display = 'block';
                }
            },
            [],
        );

        // The message handler (to manage iframes)
        const postMessageHandler = React.useCallback(
            (message) => {
                if (message.origin === lyriaUrl && message.data) {
                    if (message.data.status === 'success') {
                        handleSuccess();
                    } else if (message.data.status === 'error') {
                        handleError(message.data.data);
                    } else if (message.data.status === 'threedsmethod') {
                        verifyEnrollment(message.data);
                    }
                }
            },
            [handleError, handleSuccess, lyriaUrl, verifyEnrollment],
        );

        // The api call to Lyria (backend) to trigger Payline payment process.
        const process = React.useCallback(
            (pspId, data, extraBodyData = {}) => {
                if (isDisabled) {
                    return;
                }
                return axios({
                    method: 'post',
                    url: lyriaUrl + '/payline/process',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        client_capabilities: getClientCapabilities(),
                        request_token: token,
                        payment_request_id: paymentRequestId,
                        psp: pspId,
                        data,
                        ...extraBodyData,
                    },
                })
                    .then((res) => {
                        showSpinner(false);
                        setDisplayDoNotLeaveWarning(false);
                        if (res?.data.status === 'V') {
                            handleSuccess(res.data.data);
                        } else if (res?.data.status === 'A') {
                            handleError(res?.data.data);
                        }
                        return res;
                    })
                    .catch((error) => {
                        if (
                            error?.response?.data?.status === 'error'
                            && error?.response?.data?.data?.code === 'verify_enrollment'
                        ) {
                            verifyEnrollment(error.response.data.data.action);
                        } else {
                            handleError({ code: 'internal' });
                            console.info(error);
                        }
                    });
            },
            [handleError, handleSuccess, isDisabled, lyriaUrl, token, paymentRequestId, verifyEnrollment],
        );

        // The api call to Lyria (backend) to trigger Payline card save process.
        const saveTokenizedCard = React.useCallback(
            (pspId, data, extraBodyData = {}) => {
                if (isDisabled) {
                    return;
                }
                return axios({
                    method: 'post',
                    url: lyriaUrl + '/payline/save_tokenized_card_data',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        request_token: token,
                        payment_request_id: paymentRequestId,
                        psp: pspId,
                        data,
                        ...extraBodyData,
                    },
                })
                    .then((res2) => {
                        showSpinner(false);
                        setDisplayDoNotLeaveWarning(false);
                        if (res2?.data.status === 'success') {
                            handleSuccess(res2.data);
                        } else if (res2?.data.status === 'error') {
                            handleError(res2?.data.data);
                        }
                    })
                    .catch((error) => {
                        handleError({ code: 'internal' });
                        console.info(error);
                    });
            },
            [handleError, handleSuccess, isDisabled, lyriaUrl, token, paymentRequestId],
        );


        // The callback logic to trigger when the user click on 'pay' button to submit the form for a new card.
        const submitForm = React.useCallback(
            () => {
                if (isDisabled) {
                    return false;
                }
                setSubmitTried(true);

                if (!(card.isValid && expiry.isValid && cvc.isValid)) {
                    return false;
                }

                if (card.cardTypeConfig === null) {
                    return false;
                }

                const tokenizer = findTokenizer(
                    card.cardTypeConfig.type,
                    config.tokenizers,
                );

                if (tokenizer === null) {
                    return false;
                }

                setReadOnly(true);
                showForm(false);
                showSpinner(true);
                setDisplayDoNotLeaveWarning(true);
                onPaymentStarted();

                axios({
                    method: tokenizer.method,
                    url: tokenizer.url,
                    headers: {
                        'Content-Type': tokenizer.content_type,
                    },
                    data: tokenizer.data
                        .replace('__card__', card.value.replace(/\s/g, ''))
                        .replace('__expiry__', expiry.value.replace('/', ''))
                        .replace('__cvx__', cvc.value),
                })
                    .then((res) => {
                        if (operation === 'credit') {
                            saveTokenizedCard(
                                tokenizer.id,
                                res?.data,
                            ).then().catch();
                        } else {
                            process(
                                tokenizer.id,
                                res?.data,
                                { save_payment_method: savePaymentMethodValue },
                            ).then().catch();
                        }
                    })
                    .catch((error) => {
                        handleError({ code: 'internal' });
                        console.info(error);
                    });

                return true;
            },
            [
                card,
                config,
                cvc,
                expiry,
                handleError,
                isDisabled,
                operation,
                onPaymentStarted,
                process,
                savePaymentMethodValue,
                saveTokenizedCard,
            ],
        );

        // The callback logic to trigger when the user click on 'pay' button to submit the form for an existing card.
        const submitFormForPayingWithSavedCard = React.useCallback(
            () => {
                if (isDisabled) {
                    return false;
                }
                setSubmitTried(true);

                if (!cvc.isValid) {
                    return false;
                }

                const tokenizer = findTokenizer(
                    selectedPaymentMethod.payment_method,
                    config.tokenizers,
                    selectedPaymentMethod.uuid,
                );

                if (tokenizer === null) {
                    return false;
                }

                setReadOnly(true);
                showForm(false);
                showSpinner(true);
                setDisplayDoNotLeaveWarning(true);
                onPaymentStarted();

                axios({
                    method: tokenizer.method,
                    url: tokenizer.url,
                    headers: {
                        'Content-Type': tokenizer.content_type,
                    },
                    data: tokenizer.data.replace('__cvx__', cvc.value),
                })
                    .then((res) => {
                        process(
                            selectedPaymentMethod.psp,
                            res?.data,
                            {
                                saved_payment_method: selectedPaymentMethod.uuid,
                                cvx: cvc.value,
                            },
                        ).then().catch();
                    })
                    .catch((error) => {
                        handleError({ code: 'internal' });
                        console.info(error);
                    });

                return true;
            },
            [cvc, isDisabled, onPaymentStarted, process, selectedPaymentMethod, config, handleError],
        );

        // The callback to trigger when the user click on 'pay' button to submit the form for a new card.
        const onClickOnPay = React.useCallback(
            (event) => {
                event.preventDefault();
                submitForm();
            },
            [submitForm],
        );

        // The callback to trigger when the user click on 'pay' button to submit the form for an existing card.
        const onClickOnPayWithSavedPaymentMethod = React.useCallback(
            (event) => {
                event.preventDefault();
                const processing = submitFormForPayingWithSavedCard();
                if (processing) {
                    event.preventDefault();
                }
            },
            [submitFormForPayingWithSavedCard],
        );

        /* ------------ The side effects and imperative handlers ------------ */

        // The side effect to add a listener of message posted by Payline.
        React.useEffect(
            () => {
                window.addEventListener('message', postMessageHandler);
                return () => window.removeEventListener('message', postMessageHandler);
            },
            [postMessageHandler],
        );

        // Select by default the first saved payment method available.
        React.useEffect(
            () => {
                if (savedPaymentMethods.length) {
                    setSelectedPaymentMethodUuid(savedPaymentMethods[0].uuid);
                }
            },
            [savedPaymentMethods],
        );

        // When we select the payment method, we get the full object with its config
        React.useEffect(
            () => {
                if (selectedPaymentMethodUuid) {
                    const selectedSpm = savedPaymentMethods.find(spm => spm.uuid === selectedPaymentMethodUuid);
                    setSelectedPaymentMethod(selectedSpm);
                    setCard({
                        value: '',
                        isValid: false,
                        cardTypeConfig: null,
                    });
                    setExpiry({
                        value: '',
                        isValid: false,
                    });
                    setCvc({
                        value: '',
                        isValid: false,
                    });
                }
            },
            [selectedPaymentMethodUuid, savedPaymentMethods],
        );

        // The handler to be able to external component to trigger 'pay' button actions.
        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    if (selectedPaymentMethodUuid) {
                        return submitFormForPayingWithSavedCard();
                    }
                    return submitForm();
                },
                canClickOnPay: selectedPaymentMethodUuid
                    ? cvc.isValid
                    : card.isValid && expiry.isValid && cvc.isValid,
            }),
            [
                card.isValid,
                cvc.isValid,
                expiry.isValid,
                selectedPaymentMethodUuid,
                submitForm,
                submitFormForPayingWithSavedCard,
            ],
        );

        /* ------------ The JSX to return according to the logic above ------------ */

        if (config.type === 'apple_pay') {
            return (
                <ApplePayButton
                    config={config.config}
                    merchantId={config.merchant_id}
                    onLoadPaymentData={(data) => {
                        onPaymentStarted();
                        return process(config.id, data, { save_payment_method: savePaymentMethodValue });
                    }}
                    labelPayButton={labelPayButtonApplePayAndGooglePay}
                    lyriaUrl={lyriaUrl}
                    lang={lang}
                    themeMode={themeMode}
                    isDisabled={isDisabled}
                />
            );
        }
        if (config.type === 'google_pay') {
            // the GooglePay payment method selector is closed as soon as the user clicks "Pay"
            // so we have to show a spinner ourselves while waiting for backend validation
            if (isSpinnerVisible) {
                return (
                    <WaitingBlock loader={loader}>
                        <div>
                            {t('waitingPaymentResult')}
                        </div>
                        {displayDoNotLeaveWarning && (
                            <div className={styles.do_not_leave}>
                                {t('waitingPaymentResultDoNotLeavePage')}
                            </div>
                        )}
                    </WaitingBlock>
                );
            } else {
                return (
                    <GooglePayButton
                        config={config.config}
                        onLoadPaymentData={(data) => {
                            onPaymentStarted();
                            showSpinner(true);
                            process(
                                config.id,
                                data,
                                { save_payment_method: savePaymentMethodValue },
                            );
                        }}
                        labelPayButton={labelPayButtonApplePayAndGooglePay}
                        lang={lang}
                        themeMode={themeMode}
                        isDisabled={isDisabled}
                    />
                );
            }
        }

        return (
            <div id="payline">
                {isSpinnerVisible && (
                    <WaitingBlock loader={loader}>
                        <div>
                            {t('waitingPaymentResult')}
                        </div>
                        {displayDoNotLeaveWarning && (
                            <div className={styles.do_not_leave}>
                                {t('waitingPaymentResultDoNotLeavePage')}
                            </div>
                        )}
                    </WaitingBlock>
                )}
                {!isSpinnerVisible && displayDoNotLeaveWarning && (
                    <div className={styles.do_not_leave_message_container}>
                        <WaitingBlock loader={null}>
                            <div className={styles.do_not_leave_message}>
                                <Trans i18nKey="waitingPaymentResultDoNotLeavePage" />
                            </div>
                        </WaitingBlock>
                    </div>
                )}
                {isFormVisible && (
                    <form noValidate>
                        {!!savedPaymentMethods?.length && savedPaymentMethods.map((spm) => (
                            <div className={styles.saved_payment_method} key={spm.uuid}>
                                <SavedCardInput
                                    uuid={spm.uuid}
                                    selected={selectedPaymentMethodUuid === spm.uuid}
                                    onClickRadio={(u) => setSelectedPaymentMethodUuid(u)}
                                    maskedCardNumber={spm.masked_number}
                                    displayExpiryDate={false}
                                    cardType={spm.card_type}
                                    content={
                                        <div className="wz-lyriapay__card-input__row--column">
                                            <div className="wz-lyriapay__card-input__row">
                                                <ExpiryInput
                                                    defaultExpiryDate={formatExpiryDate(spm.expiry)}
                                                    disabled
                                                />
                                            </div>
                                            <div className="wz-lyriapay__card-input__row">
                                                <CvxInput
                                                    disabled={isReadOnly}
                                                    onChange={setCvc}
                                                    forceDirty={submitTried}
                                                    length={config?.card_types?.find(ct => ct.type.toLowerCase() === spm.payment_method.toLowerCase())?.cvc_length || [3, 4]}
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                        ))}
                        {(savedPaymentMethods?.length === 0 || !selectedPaymentMethodUuid) && (
                            <div className={styles.input}>
                                <CardInput
                                    displaySavePaymentMethodCheckbox={savePaymentMethod && selectedPaymentMethodUuid === null}
                                    onChangeSavePaymentMethod={setSavePaymentMethodValue}
                                    defaultSavePaymentMethod={savePaymentMethodValue}
                                    onChangeCardNumber={setCard}
                                    configCardTypes={config.card_types}
                                    onChangeExpiryDate={setExpiry}
                                    onChangeCvx={setCvc}
                                    lengthCvx={card?.cardTypeConfig?.cvc_length || [3, 4]}
                                    disabled={isReadOnly}
                                    forceDirty={submitTried}
                                />
                                {!!paymentCardInputWarningMessage && (
                                    <InfoBlock
                                        title={t('payment_card_input_warning_message_title')}
                                        message={paymentCardInputWarningMessage}
                                    />
                                )}
                            </div>
                        )}
                        {!!savedPaymentMethods?.length && selectedPaymentMethodUuid && (
                            <div className={styles.button_new_payment_method}>
                                <ButtonLink
                                    onClick={() => setSelectedPaymentMethodUuid(null)}
                                    label={t('useNewPaymentMethod')}
                                    startIcon={<Icon id="add" themeMode={themeMode} />}
                                />
                            </div>
                        )}
                        {!hidePayButton && (
                            <div className={styles_psp.common_psp_submit}>
                                <Button
                                    hideButton={hidePayButton}
                                    disabled={
                                        isDisabled
                                        || (
                                            selectedPaymentMethodUuid
                                                ? !cvc.isValid
                                                : !card.isValid || !expiry.isValid || !cvc.isValid
                                        )
                                    }
                                    label={labelPayButton || t('payButton')}
                                    onClick={selectedPaymentMethodUuid ? onClickOnPayWithSavedPaymentMethod : onClickOnPay}
                                    type='submit'
                                />
                            </div>
                        )}
                    </form>
                )}
                <iframe
                    className={styles_psp.common_iframe}
                    title="iframe3ds"
                    ref={iframe3ds}
                    /* Recreate a new iframe for each time we try the payment */
                    key={`iframe3ds${attempts}`}
                    style={isIframeShown ? {} : { display: 'none' }}
                />
                <iframe
                    title="iframe3dsmethod"
                    ref={iframe3dsmethod}
                    /* Recreate a new iframe for each time we try the payment */
                    key={`iframe3dsmethod${attempts}`}
                    style={{ display: 'none' }}
                />
            </div>
        );
    },
);

Payline.propTypes = {
    /**
     * The specific configuration related to this payment method.
     */
    config: PropTypes.object,
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string,
    /**
     * The paymentRequestID to use in order to be able to call the backend when we submit the data in the paymentPage.
     */
    paymentRequestId: PropTypes.string,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The label of the 'pay' button to display for GogglePay and ApplePay.
     */
    labelPayButtonApplePayAndGooglePay: PropTypes.oneOf([
        'buy',
        'book',
        'donate',
        'order',
        'pay',
        'logoOnly',
    ]),
    /**
     * The callback function to trigger when the payment process is starting (to manage Payline exchanges).
     */
    onPaymentStarted: PropTypes.func.isRequired,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
    /**
     * The loader component to use when the payment or drop-in is loading.
     */
    loader: PropTypes.node,
    /**
     * Whether we want to check by default the checkbox to save the payment method.
     */
    savePaymentMethod: PropTypes.bool,
    /**
     * The list of saved payment methods available.
     * The goal is to be able to select one of them and avoid typing it again).
     */
    savedPaymentMethods: PropTypes.array,
    /**
     * A warning message to display for payment at the card input step (mainly to advise the user to save its payment method).
     */
    paymentCardInputWarningMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * The operation type to process.
     */
    operation: PropTypes.oneOf(['credit', 'payment', 'refund']),
    /**
     * The language of the user in order to display the appropriate translated drop-in (for Apple pay).
     */
    lang: PropTypes.string,
    /**
     * The theme to use (dark or light).
     */
    themeMode: PropTypes.oneOf(['light', 'dark']),
    /**
     * A fonction to call when we have a payment Error, like payment decline
     * payment aborted, or anything that can happen after you put in you credit
     * card number to make the transaction fail while you really want to buy
     * this concert ticket
     */
    onPaymentError: PropTypes.func,
    /**
     * Whether the payment method is disabled, and must not trigger payment or not.
     * Mainly used for Google Pay and Apple Pay buttons.
     */
    isDisabled: PropTypes.bool,
};

Payline.defaultProps = {
    config: {},
    labelPayButton: null,
    labelPayButtonApplePayAndGooglePay: 'buy',
    hidePayButton: false,
    loader: null,
    savePaymentMethod: false,
    savedPaymentMethods: [],
    paymentCardInputWarningMessage: null,
    operation: null,
    lang: 'fr',
    themeMode: 'light',
    onPaymentError: () => { },
    isDisabled: false,
};

Payline.displayName = 'Payline';

export default Payline;
