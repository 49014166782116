import React from 'react';
import PropTypes from 'prop-types';

const IngenicoLegacyForm = ({payload, formEl, config}) => {
    return (
        <form method='post' ref={formEl} action={config.ogone_url} id='form1' name='form1'>

            <input type='hidden' name='PSPID' value={payload.PSPID}/>
            <input type='hidden' name='ORDERID' value={payload.ORDERID}/>
            <input type='hidden' name='AMOUNT' value={payload.AMOUNT}/>
            <input type='hidden' name='CURRENCY' value={payload.CURRENCY}/>
            <input type='hidden' name='LANGUAGE' value={payload.LANGUAGE}/>
            <input type='hidden' name='CN' value=''/>
            <input type='hidden' name='EMAIL' value={payload.EMAIL||''}/>
            <input type='hidden' name='OWNERZIP' value=''/>
            <input type='hidden' name='OWNERADDRESS' value=''/>
            <input type='hidden' name='OWNERCTY' value=''/>
            <input type='hidden' name='OWNERTOWN' value=''/>
            <input type='hidden' name='OWNERTELNO' value=''/>
            <input type='hidden' name='PM' value={payload.PM||''}/>
            <input type='hidden' name='PMLIST' value={payload.PMLIST||''}/>


            <input type='hidden' name='SHASIGN' value={payload.SHASIGN}/>

            <input type='hidden' name='TITLE' value=''/>
            <input type='hidden' name='BGCOLOR' value=''/>
            <input type='hidden' name='TXTCOLOR' value=''/>
            <input type='hidden' name='TBLBGCOLOR' value=''/>
            <input type='hidden' name='TBLTXTCOLOR' value=''/>
            <input type='hidden' name='BUTTONBGCOLOR' value=''/>
            <input type='hidden' name='BUTTONTXTCOLOR' value=''/>
            <input type='hidden' name='LOGO' value=''/>
            <input type='hidden' name='FONTTYPE' value=''/>


            <input type='hidden' name='ACCEPTURL' value={payload.ACCEPTURL||''}/>
            <input type='hidden' name='DECLINEURL' value={payload.DECLINEURL||''}/>
            <input type='hidden' name='EXCEPTIONURL' value={payload.EXCEPTIONURL||''}/>
            <input type='hidden' name='CANCELURL' value={payload.CANCELURL||''}/>
        </form>
    );
};

IngenicoLegacyForm.propTypes = {
    /**
     * The specific configuration related to this payment method (IBAN).
     */
    payload: PropTypes.object,
    formEl: PropTypes.object,
    config: PropTypes.object,
};

export default IngenicoLegacyForm;
