import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'lib/components/Button';
import FormField from 'lib/components/FormField';
import { cleanObjectOnValues } from 'lib/utils/objectUtils';
import { useFormSave } from 'lib/hooks/useFormSave';
import styles from './HongKongForm.module.scss';
import { bankCodeMap } from './constants';

import styles_psp from 'lib/components/PspComposer/PspComposer.module.scss';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents a form used for Hong Kong banks,
 * and some information related to Hong Kong. It is only used to collect refund request data.
 */
const HongKongForm = React.forwardRef(
    (
        {
            pspId,
            token,
            paymentRequestId,
            lyriaUrl,
            hidePayButton,
            labelPayButton,
            onPaymentResult,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const formRef = React.useRef(null);
        const [formData, setFormData] = React.useState({
            'firstName': '',
            'lastName': '',
            'bankAccountNumber': '',
            'bankCode': '',
        });
        const [errors, setErrors] = React.useState({});
        const [bankCodeIsValid, setBankCodeIsValid] = React.useState(false);
        const [bankAccountIsValid, setBankAccountIsValid] = React.useState(false);
        const [bankName, setBankName] = React.useState('');
        const [formFieldBlurState, setFormFieldBlurState] = React.useState({
            bankAccountNumber: false,
            bankCode: false,
        });

        const handleSuccess = React.useCallback(
            (response) => {
                onPaymentResult({ success: true, data: response });
            },
            [onPaymentResult],
        );

        const handleError = React.useCallback(
            (response) => {
                if (response?.data) {
                    const fieldErrors = {};
                    Object
                        .keys(response.data)
                        .forEach((key) => {
                            fieldErrors[key] = response.data[key] && response.data[key].map((error) => t([
                                `hong_kong_form_${key}_error_${error.code}`,
                                `error_${error.code}`,
                                'error_form',
                            ]));
                        });
                    setErrors(fieldErrors);
                }
                onPaymentResult({
                    success: false,
                    data: response,
                });
            },
            [onPaymentResult, t],
        );

        const url = lyriaUrl + '/hong-kong-form/save';
        const saveForm = useFormSave(url, token, paymentRequestId, pspId, handleSuccess, handleError);

        const submitForm = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                const rawData = {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    bank_account_number: formData.bankAccountNumber,
                    bank_code: formData.bankCode,
                };

                const data = cleanObjectOnValues(rawData, ['', null]);
                saveForm(data);
            },
            [formData, saveForm],
        );

        const formatAndValidateBankCode = React.useCallback(
            (value) => {
                let bankCodeError = null;
                if (!bankCodeMap[value]) {
                    bankCodeError = [t('hong_kong_form_bank_code_error')];
                }
                setErrors((prevState) => ({
                    ...prevState,
                    bankCode: bankCodeError,
                }));
                let isValid = bankCodeError === null && value.length === 3;
                if (isValid) {
                    setBankName(bankCodeMap[value]);
                }
                setBankCodeIsValid(isValid);
                return value;
            },
            [t, setErrors, setBankCodeIsValid],
        );

        const formatAndValidateBankAccount = React.useCallback(
            (value) => {
                let bankAccountError = null;
                if (isNaN(value) || value.length !== 9) {
                    bankAccountError = [t('hong_kong_form_bank_account_number_error')];
                }
                setErrors((prevState) => ({
                    ...prevState,
                    bankAccountNumber: bankAccountError,
                }));
                let isValid = bankAccountError === null;
                setBankAccountIsValid(isValid);
                return value;
            },
            [t, setErrors, setBankAccountIsValid],
        );


        const onBlurFormField = React.useCallback(
            (fieldName) => (_event) => {
                setFormFieldBlurState((prevState) => ({
                    ...prevState,
                    [fieldName]: true,
                }));
            },
            [],
        );

        const onChangeFormField = React.useCallback(
            (fieldName) => (event, newValue) => {
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: newValue,
                }));

            },
            [],
        );

        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    return formRef.current.click();
                },
                canClickOnPay: Object.values(formData).every((value) => !!value),
            }),
            [formData],
        );

        const requiredFields = [
            'firstName',
            'lastName',
            'bankAccountNumber',
            'bankCode',
        ];
        const hasRequiredFieldNotFilled = Object
            .values(
                Object.fromEntries(
                    Object
                        .entries(formData)
                        .filter(([k, _v]) => requiredFields.includes(k)),
                ),
            )
            .some((value) => !value);

        return (
            <div id="wz-lyriapay__hong-kong-form" className={styles_psp.common_psp}>
                <form noValidate onSubmit={submitForm}>
                    <div className={styles_psp.common_psp_form}>
                        <div className={styles_psp.common_field_group}>
                            <FormField
                                name="firstName"
                                value={formData?.firstName}
                                onChange={onChangeFormField('firstName')}
                                label={t('hong_kong_form_first_name')}
                                errors={errors.firstName}
                                required
                            />
                            <FormField
                                name="lastName"
                                value={formData?.lastName}
                                onChange={onChangeFormField('lastName')}
                                label={t('hong_kong_form_last_name')}
                                errors={errors.lastName}
                                required
                            />
                        </div>
                        <FormField
                            name="bankAccountNumber"
                            value={formData?.bankAccountNumber}
                            onChange={onChangeFormField('bankAccountNumber')}
                            label={t('hong_kong_form_bank_account_number')}
                            errors={formFieldBlurState.bankAccountNumber && errors.bankAccountNumber ? errors.bankAccountNumber : []}
                            onBlur={onBlurFormField('bankAccountNumber')}
                            formatAndValidate={formatAndValidateBankAccount}
                            inputProps={{
                                maxLength: '9',
                            }}
                            required
                        />
                        <FormField
                            name="bankCode"
                            value={formData.bankCode}
                            onChange={onChangeFormField('bankCode')}
                            label={t('hong_kong_form_bank_code')}
                            errors={formFieldBlurState.bankCode && errors.bankCode ? errors.bankCode : []}
                            onBlur={onBlurFormField('bankCode')}
                            formatAndValidate={formatAndValidateBankCode}
                            inputProps={{
                                maxLength: '3',
                            }}
                            required
                        />
                        {bankCodeIsValid ? <div className={styles.name_description}>
                            {bankName}
                        </div> : null}
                    </div>
                    {!hidePayButton && (
                        <div className={styles_psp.common_psp_submit}>
                            <Button
                                disabled={hasRequiredFieldNotFilled || !bankCodeIsValid || !bankAccountIsValid}
                                label={labelPayButton || t('payButton')}
                                ref={formRef}
                                type='submit'
                            />
                        </div>
                    )}
                </form>
            </div>
        );
    },
);

HongKongForm.propTypes = {
    /**
     * The is of the PSP returned by the backend in order to be able to pass it when we submit the form data.
     */
    pspId: PropTypes.number.isRequired,
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string,
    /**
    * The paymentRequestID to use in order to be able to call the backend when we submit the data in the paymentPage.
    */
    paymentRequestId: PropTypes.string,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
};

HongKongForm.defaultProps = {
    hidePayButton: false,
    labelPayButton: null,  // Fallback on 'pay' or 'ask refund' label.
};

HongKongForm.displayName = 'HongKongForm';

export default HongKongForm;
