import React from 'react';

import GooglePayButtonExt from '@google-pay/button-react';
import PropTypes from 'prop-types';

import styles from './GooglePayButton.module.scss';
import styles_payment_method_option_express from 'lib/components/PaymentMethodOptionExpress/PaymentMethodOptionExpress.module.scss';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents the Google Pay button which is a particular integration of an existing PSP.
 * So, it's not really a PSP but just a kind of external card provider we will use with the 'classic' PSP.
 */
export const GooglePayButton = ({
    config,
    onLoadPaymentData,
    labelPayButton,
    lang,
    themeMode,
    isDisabled,
}) => {
    const requestAndEnvironment = config.request;
    const { environment, ...request } = requestAndEnvironment;

    const labelPayButtonMapping = {
        buy: 'buy',
        book: 'book',
        donate: 'donate',
        order: 'order',
        pay: 'pay',
        logoOnly: 'plain',
    };

    const updateButtonStyle = React.useCallback(
        () => {
            const section = document.getElementById(styles_payment_method_option_express.payment_method_option_express);
            if (!section) {
                return;
            }
            const buttons1 = section.getElementsByClassName(styles.gpay_button);
            const buttons2 = section.getElementsByClassName('gpay-card-info-container');
            let button = null;
            if (buttons1.length) {
                button = buttons1[0];
            } else if (buttons2.length) {
                button = buttons2[0];
            }
            if (isDisabled && button) {
                button?.setAttribute('disabled', true);
                button.style.opacity = 0.4;
            } else if (button) {
                button?.removeAttribute('disabled');
                button.style.opacity = 1;
            }
        },
        [isDisabled],
    );

    React.useEffect(
        () => {
            updateButtonStyle();
            // This useInterval is necessary because the render of the button is asynchronous and when one prop change
            // the whole button is rerender but not immediately.
            const interval = setInterval(updateButtonStyle, 1000);
            return () => clearInterval(interval);
        },
        [updateButtonStyle],
    );

    return (
        <div id='wz-lyriapay__google-pay-button'>
            <GooglePayButtonExt
                buttonLocale={lang}
                buttonColor={themeMode === 'dark' ? 'white' : 'black'}
                environment={environment}
                paymentRequest={request}
                onLoadPaymentData={onLoadPaymentData}
                existingPaymentMethodRequired={false}
                buttonType={labelPayButtonMapping[labelPayButton] || 'plain'}
                onReadyToPayChange={(_result) => null}
            />
        </div>
    );
};

GooglePayButton.propTypes = {
    /**
     * The specific config object for Google Pay
     */
    config: PropTypes.object,
    /**
     * TODO
     */
    onLoadPaymentData: PropTypes.func,
    /**
     * The label of the 'pay' button to display. If logoOnly set, no label displayed.
     */
    labelPayButton: PropTypes.oneOf([
        'buy',
        'book',
        'donate',
        'order',
        'pay',
        'logoOnly',
    ]),
    /**
     * The language code to pass to Google to display the label according to the user locale.
     */
    lang: PropTypes.string,
    /**
     * The theme mode to display the appropriate version of the icon (if exist).
     */
    themeMode: PropTypes.oneOf(['light', 'dark']),
    /**
     * Whether the button is disabled, and must not trigger payment or not.
     */
    isDisabled: PropTypes.bool,
};

GooglePayButton.defaultProps = {
    tokenizer: null,
    onLoadPaymentData: null,
    labelPayButton: 'buy',
    lang: 'en',
    themeMode: 'light',
    isDisabled: false,
};

GooglePayButton.displayName = 'GooglePayButton';

export default GooglePayButton;
