import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormField from 'lib/components/FormField';
import PaymentsOSErrorLabel from '../PaymentsOSErrorLabel';
import styles from './PaymentsOSCardInput.module.scss';

const PaymentsOSCardInput = React.forwardRef(
    (
        {
            config,
            displaySavePaymentMethodCheckbox,
            onChangeSavePaymentMethod,
            defaultSavePaymentMethod,
            onFormElementError,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const [cardNumber, setCardNumber] = React.useState(null);
        const [cardHolderName, setCardHolderName] = React.useState('');
        const [savePaymentMethod, setSavePaymentMethod] = React.useState(defaultSavePaymentMethod);

        const [isCardHolderNameDirty, setIsCardHolderNameDirty] = React.useState(false);
        const [submitTried, setSubmitTried] = React.useState(false);
        const [errorCardNumber, setErrorCardNumber] = React.useState(undefined);
        const [errorExpiryDate, setErrorExpiryDate] = React.useState(undefined);
        const [errorCvv, setErrorCvv] = React.useState(undefined);

        const onChangeSavePaymentMethodValue = React.useCallback(
            (event, newValue) => {
                if (onChangeSavePaymentMethod) {
                    onChangeSavePaymentMethod(newValue);
                }
                setSavePaymentMethod(newValue);
            },
            [onChangeSavePaymentMethod],
        );

        const create = React.useCallback(
            () => {
                const fonts = [{ src: 'https://fonts.googleapis.com/css?family=Source+Code+Pro' }];

                const formElements = new window.POS.Fields(config.public_key, { fonts });

                const placeholders = {
                    cardNumber: '1234 1234 1234 1234',
                    expDate: 'MM/YY',
                    cvv: '123',
                };

                // Instantiate the fields to show and mount them to the DOM.
                const cardNumberFormElement = formElements.create('cardNumber', { placeholders });
                cardNumberFormElement.mount('#card-number');
                cardNumberFormElement.on('change', (event) => {
                    if (event.error === undefined) {
                        setErrorCardNumber(undefined);
                        onFormElementError({ card: undefined });
                    } else {
                        setErrorCardNumber(event.error?.pan);
                        onFormElementError({ card: event.error?.pan });
                    }

                });
                setCardNumber(cardNumberFormElement);

                const expiryFormElement = formElements.create('creditCardExpiry', { placeholders });
                expiryFormElement.mount('#exp-date');
                expiryFormElement.on('change', (event) => {
                    if (event.error === undefined) {
                        setErrorExpiryDate(undefined);
                        onFormElementError({ expiry: undefined });
                    } else {
                        setErrorExpiryDate(event.error?.expiry);
                        onFormElementError({ expiry: event.error?.expiry });
                    }
                });

                const cvvFormElement = formElements.create('cvv', { placeholders });
                cvvFormElement.mount('#cvv');
                cvvFormElement.on('change', (event) => {
                    if (event.error === undefined) {
                        setErrorCvv(undefined);
                        onFormElementError({ cvv: undefined });
                    } else {
                        setErrorCvv(event.error?.cvv);
                        onFormElementError({ cvv: event.error?.cvv });
                    }
                });
            },
            [config.public_key, onFormElementError],
        );

        /* ------------ The side effects and imperative handlers ------------ */

        React.useEffect(
            () => {
                const script = window.document.createElement('script');
                script.src = 'https://js.paymentsos.com/v3/latest/secure-fields.min.js';
                script.onload = create;
                window.document.body.appendChild(script);
                return () => window.document.body.removeChild(script);
            },
            [create],
        );

        React.useImperativeHandle(
            ref,
            () => ({
                tokenize() {
                    const additionalData = {
                        // Holder name field is mandatory
                        holder_name: document.getElementById('cardholder-name').value,
                    };
                    return window.POS.createToken(
                        cardNumber,
                        {
                            additionalData,
                            // the PaymentsOS environment you're connecting to
                            environment: config.production ? 'live' : 'test',
                        },
                    );
                },
                clickedSubmit() {
                    setSubmitTried(true);
                },
            }),
            [cardNumber, config],
        );

        /* ------------ The JSX to return according to the logic above ------------ */

        return (
            <div className={styles.input}>
                <div className={styles.row}>
                    <FormField
                        id="cardholder-name"
                        name="cardholder-name"
                        type="text"
                        label={t('cardHolderName')}
                        value={cardHolderName}
                        onChange={(event, newValue) => {
                            setIsCardHolderNameDirty(true);
                            setCardHolderName(newValue);
                        }}
                        errors={(isCardHolderNameDirty || submitTried) && cardHolderName === '' ? [t('error_required')] : null}
                    />
                </div>
                <div className={`${styles.row} ${styles.external_field}`}>
                    <label htmlFor="card-number">{t('cardNumberLabel')}</label>
                    <div id="card-number" className={styles.wrapper} />
                    <PaymentsOSErrorLabel errorContent={errorCardNumber ? t('cardNumberInvalid') : null} />
                </div>
                <div className={styles.column}>
                    <div className={`${styles.row} ${styles.external_field}`}>
                        <label htmlFor="exp-date">{t('expiryLabel')}</label>
                        <div id="exp-date" className={styles.wrapper} />
                        <PaymentsOSErrorLabel errorContent={errorExpiryDate ? t('expiryInvalid') : null} />
                    </div>
                    <div className={`${styles.row} ${styles.external_field}`}>
                        <label htmlFor="cvv">{t('cvcLabel')}</label>
                        <div id="cvv" className={styles.wrapper} />
                        <PaymentsOSErrorLabel errorContent={errorCvv ? t('cvcInvalid') : null} />
                    </div>
                </div>
                {displaySavePaymentMethodCheckbox && (
                    <div className={styles.row}>
                        <FormField
                            name='savepaymentmethod'
                            label={t('savePaymentMethod')}
                            checked={savePaymentMethod}
                            onChange={onChangeSavePaymentMethodValue}
                            type='checkbox'
                        />
                    </div>
                )}
            </div>
        );
    });

PaymentsOSCardInput.propTypes = {
    /**
     * The specific configuration related to this payment method (Payments OS).
     */
    config: PropTypes.object,
    /**
     * Whether we have to display the checkbox to let the user choose if he wants to save his payment method.
     */
    displaySavePaymentMethodCheckbox: PropTypes.bool,
    /**
     * The callback to trigger when the user click on the checkbox to save his payment method.
     * It should handle the new boolean value.
     */
    onChangeSavePaymentMethod: PropTypes.func,
    /**
     * The default value of the checkbox about save payment method.
     */
    defaultSavePaymentMethod: PropTypes.bool,
    onFormElementError: PropTypes.func,
};

PaymentsOSCardInput.defaultProps = {
    config: {},
    displaySavePaymentMethodCheckbox: false,
    onChangeSavePaymentMethod: null,
    defaultSavePaymentMethod: false,
    onFormElementError: null,
};

PaymentsOSCardInput.displayName = 'PaymentsOSCardInput';

export default PaymentsOSCardInput;
