import React  from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'lib/components/Button';
import ButtonLink from 'lib/components/ButtonLink';
import Icon from 'lib/components/Icon';
import InfoBlock from 'lib/components/InfoBlock';
import PaymentsOSCardInput from './components/PaymentsOSCardInput';
import PaymentsOSCvvForm from './components/PaymentsOSCvxInput';
import SavedCardInput from 'lib/components/SavedCardInput';
import WaitingBlock from 'lib/components/WaitingBlock';
import { formatExpiryDate } from 'lib/utils/cardUtils';
import styles from './PaymentsOS.module.scss';
import styles_psp from 'lib/components/PspComposer/PspComposer.module.scss';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents the Payments OS PSP integration that can deal with multiple bank card types.
 */
const PaymentsOS = React.forwardRef(
    (
        {
            config,
            token,
            lyriaUrl,
            paymentRequestId,
            onPaymentStarted,
            onPaymentResult,
            hidePayButton,
            labelPayButton,
            loader,
            savePaymentMethod,
            savedPaymentMethods,
            paymentCardInputWarningMessage,
            operation, // eslint-disable-line no-unused-vars
            themeMode,
            onPaymentError,
        },
        ref,
    ) => {
        /* ------------ Initialization of the imports, the variables and the states ------------ */

        // Hooks
        const { t } = useTranslation();

        const iframe3ds = React.useRef(null);
        const [attempts, setAttempts] = React.useState(0);

        const paymentsOSCardFormRef = React.useRef();
        const paymentsOSCvvFormRef = React.useRef();
        // Display behavior states
        const [isIframeShown, showIframe] = React.useState(false);
        const [isFormVisible, showForm] = React.useState(true);
        const [isSpinnerVisible, showSpinner] = React.useState(false);
        // Payment method fields and fields related states
        const [cardNumberCorrect, setCardNumberCorrect] = React.useState(null);
        const [expiryDateCorrect, setExpiryDateCorrect] = React.useState(null);
        const [cvvCorrect, setCvvCorrect] = React.useState(null);
        const [savePaymentMethodValue, setSavePaymentMethodValue] = React.useState(false);
        const [selectedPaymentMethodUuid, setSelectedPaymentMethodUuid] = React.useState(null);

        /* ------------ The main functions ------------ */

        // The function to trigger when Payments OS response is successful (payment ok).
        const handleSuccess = React.useCallback(
            (response) => {
                onPaymentResult({ success: true, data: response });
                showForm(false);
                showIframe(false);
                showSpinner(false);
            },
            [onPaymentResult],
        );

        // The function to trigger when Payline response is not successful (payment ko).
        const handleError = React.useCallback(
            (response) => {
                setAttempts((attempts) => attempts + 1);
                showForm(true);
                showSpinner(false);
                onPaymentResult({ success: false, data: response });
                onPaymentError(response);
            },
            [onPaymentResult, onPaymentError],
        );

        const onFormElementError = React.useCallback(
            (error) => {
                if ('card' in error) {
                    setCardNumberCorrect(!error.card);
                }
                if ('expiry' in error) {
                    setExpiryDateCorrect(!error.expiry);
                }
                if ('cvv' in error) {
                    setCvvCorrect(!error.cvv);
                }
            },
            [],
        );

        // The function to check the enrollment of the card when 3DS method happens.
        const verify3DS = React.useCallback(
            (redirect) => {
                showForm(false);

                let iframe;
                iframe = iframe3ds.current;
                showIframe(true);
                if (!iframe) {
                    // Avoid sentry noise: but to investigate why the reference is null (maybe a rerender)
                    console.error('Impossible to retrieve iframe');
                    return;
                }

                const iframeContent = iframe.contentDocument;
                let form = iframeContent.createElement('form');
                form.setAttribute('method', 'GET');
                form.setAttribute('action', redirect.url);
                Object.keys(redirect.query_params).forEach((key) => {
                    let hiddenField = iframeContent.createElement('input');
                    hiddenField.setAttribute('name', key);
                    hiddenField.setAttribute('type', 'hidden');
                    hiddenField.setAttribute('value', redirect.query_params[key]);
                    form.appendChild(hiddenField);
                });
                iframeContent.documentElement.appendChild(form);
                form.submit();
                showSpinner(false);
            },
            [],
        );

        // The message handler (to manage iframes)
        const postMessageHandler = React.useCallback(
            (message) => {
                if (message.origin === lyriaUrl && message.data) {
                    if (message.data.status === 'success') {
                        handleSuccess();
                    } else if (message.data.status === 'error') {
                        handleError(message.data.data);
                    }
                }
            },
            [handleError, handleSuccess, lyriaUrl],
        );

        const onTokenizedCvv = React.useCallback(
            (tokenizedCvv) => {
                if (!tokenizedCvv) {
                    return false;
                }

                const tokenizedCvvJson = JSON.parse(tokenizedCvv);

                if (tokenizedCvvJson.category) {
                    paymentsOSCvvFormRef.current.hasCvvError();
                    return false;
                }

                showForm(false);
                showSpinner(true);

                axios({
                    method: 'post',
                    url: lyriaUrl + '/paymentsos/process',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        request_token: token,
                        payment_request_id: paymentRequestId,
                        psp: config.id,
                        cvx: tokenizedCvvJson.token,
                        saved_payment_method: selectedPaymentMethodUuid,
                    },
                })
                    .then((res) => {
                        showSpinner(false);
                        if (res?.data.status === 'V') {
                            handleSuccess();
                        } else if (res?.data.status === 'A') {
                            handleError(res?.data.data);
                        }
                    })
                    .catch((error) => {
                        if (
                            error?.response?.data?.status === 'error'
                            && error?.response?.data?.data?.code === '3DS_redirection'
                        ) {
                            verify3DS(error.response.data.data.action);
                        } else {
                            handleError({ code: 'internal' });
                            console.info(error);
                        }
                    });
            },
            [config, handleError, handleSuccess, lyriaUrl, selectedPaymentMethodUuid, token, paymentRequestId, verify3DS],
        );

        // The callback logic to trigger when the user click on 'pay' button to submit the form for a new card.
        const submitForm = React.useCallback(
            async () => {
                const cardHolderName = document.getElementById('cardholder-name').value;
                paymentsOSCardFormRef.current.clickedSubmit();
                if (!cardHolderName || cardHolderName === '') {
                    return false;
                }

                showForm(false);
                showSpinner(true);
                onPaymentStarted();

                const result = await paymentsOSCardFormRef.current.tokenize();

                axios({
                    method: 'post',
                    url: lyriaUrl + '/paymentsos/process',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        request_token: token,
                        payment_request_id: paymentRequestId,
                        psp: config.id,
                        save_payment_method: savePaymentMethodValue,
                        tokenized_data: JSON.parse(result),
                    },
                })
                    .then((res) => {
                        showSpinner(false);
                        if (res?.data.status === 'V') {
                            handleSuccess(res.data.data);
                        } else if (res?.data.status === 'A') {
                            handleError(res?.data.data);
                        }
                    })
                    .catch((error) => {
                        if (
                            error?.response?.data?.status === 'error'
                            && error?.response?.data?.data?.code === '3DS_redirection'
                        ) {
                            verify3DS(error.response.data.data.action);
                        } else {
                            handleError({ code: 'internal' });
                            console.info(error);
                        }
                    });

                return true;
            },
            [
                onPaymentStarted,
                lyriaUrl,
                token,
                paymentRequestId,
                config,
                savePaymentMethodValue,
                handleSuccess,
                handleError,
                verify3DS,
            ],
        );

        // The callback logic to trigger when the user click on 'pay' button to submit the form for an existing card.
        const submitFormForPayingWithSavedCard = React.useCallback(
            () => {
                paymentsOSCvvFormRef.current.tokenize();
            },
            [],
        );

        // The callback to trigger when the user click on 'pay' button to submit the form for a new card.
        const onClickOnPay = React.useCallback(
            (event) => {
                event.preventDefault();
                if (cardNumberCorrect && expiryDateCorrect && cvvCorrect) {
                    submitForm().then().catch();
                }
            },
            [cardNumberCorrect, cvvCorrect, expiryDateCorrect, submitForm],
        );

        // The callback to trigger when the user click on 'pay' button to submit the form for an existing card.
        const onClickOnPayWithSavedPaymentMethod = React.useCallback(
            (event) => {
                event.preventDefault();
                submitFormForPayingWithSavedCard();
            },
            [submitFormForPayingWithSavedCard],
        );

        /* ------------ The side effects and imperative handlers ------------ */
        // The side effect to add a listener of message posted by Payline.

        React.useEffect(
            () => {
                window.addEventListener('message', postMessageHandler);
                return () => window.removeEventListener('message', postMessageHandler);
            },
            [postMessageHandler],
        );

        // Select by default the first saved payment method available.
        React.useEffect(
            () => {
                if (savedPaymentMethods.length) {
                    setSelectedPaymentMethodUuid(savedPaymentMethods[0].uuid);
                }
            },
            [savedPaymentMethods],
        );

        // The handler to be able to external component to trigger 'pay' button actions.
        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    if (selectedPaymentMethodUuid) {
                        return submitFormForPayingWithSavedCard();
                    } else {
                        if (cardNumberCorrect && expiryDateCorrect && cvvCorrect) {
                            return submitForm();
                        }
                    }
                },
                canClickOnPay: !!selectedPaymentMethodUuid || (cardNumberCorrect && expiryDateCorrect && cvvCorrect),
            }),
            [
                cardNumberCorrect,
                cvvCorrect,
                expiryDateCorrect,
                selectedPaymentMethodUuid,
                submitForm,
                submitFormForPayingWithSavedCard,
            ],
        );

        /* ------------ The JSX to return according to the logic above ------------ */

        return (
            <div id="payments_os">
                {isSpinnerVisible && (
                    <WaitingBlock loader={loader}>
                        {t('waitingPaymentProcess')}
                    </WaitingBlock>
                )}
                {isFormVisible && (
                    <form noValidate>
                        {!!savedPaymentMethods.length && savedPaymentMethods.map((spm) => (
                            <div key={spm.uuid}>
                                <SavedCardInput
                                    uuid={spm.uuid}
                                    selected={selectedPaymentMethodUuid === spm.uuid}
                                    onClickRadio={(u) => setSelectedPaymentMethodUuid(u)}
                                    maskedCardNumber={spm.masked_number}
                                    expiryDate={formatExpiryDate(spm.expiry)}
                                    cardType={spm.card_type}
                                    content={
                                        <PaymentsOSCvvForm
                                            config={config}
                                            savedPaymentMethod={spm}
                                            onResult={onTokenizedCvv}
                                            ref={paymentsOSCvvFormRef}
                                        />
                                    }
                                />
                            </div>
                        ))}
                        {(savedPaymentMethods.length === 0 || !selectedPaymentMethodUuid) && (
                            <div className={styles.input}>
                                <PaymentsOSCardInput
                                    ref={paymentsOSCardFormRef}
                                    config={config}
                                    displaySavePaymentMethodCheckbox={savePaymentMethod && selectedPaymentMethodUuid === null}
                                    onChangeSavePaymentMethod={(v) => setSavePaymentMethodValue(v)}
                                    defaultSavePaymentMethod={savePaymentMethodValue}
                                    onFormElementError={onFormElementError}
                                />
                                {!!paymentCardInputWarningMessage && (
                                    <InfoBlock
                                        title={t('payment_card_input_warning_message_title')}
                                        message={paymentCardInputWarningMessage}
                                    />
                                )}
                            </div>
                        )}
                        {!!savedPaymentMethods.length && selectedPaymentMethodUuid && (
                            <div className={styles.button}>
                                <ButtonLink
                                    onClick={() => setSelectedPaymentMethodUuid(null)}
                                    label={t('useNewPaymentMethod')}
                                    startIcon={<Icon id="add" themeMode={themeMode} />}
                                />
                            </div>
                        )}
                        {!hidePayButton && (
                            <div className={styles_psp.common_psp_submit}>
                                <Button
                                    hideButton={hidePayButton}
                                    disabled={
                                        selectedPaymentMethodUuid
                                            ? false
                                            : !cardNumberCorrect || !expiryDateCorrect || !cvvCorrect
                                    }
                                    label={labelPayButton || t('payButton')}
                                    onClick={selectedPaymentMethodUuid ? onClickOnPayWithSavedPaymentMethod : onClickOnPay}
                                    type='submit'
                                />
                            </div>
                        )}
                    </form>
                )}
                <iframe
                    className={styles_psp.common_iframe}
                    title="iframe3ds"
                    ref={iframe3ds}
                    key={`iframe3ds${attempts}`}
                    style={isIframeShown ? {} : { display: 'none' }}
                />
            </div>
        );
    },
);

PaymentsOS.propTypes = {
    /**
     * The specific configuration related to this payment method (Payments OS).
     */
    config: PropTypes.object,
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string,
    /**
     * The paymentRequestID to use in order to be able to call the backend when we submit the data in the paymentPage.
     */
    paymentRequestId: PropTypes.string,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The callback function to trigger when the payment process is starting (to manage Payline exchanges).
     */
    onPaymentStarted: PropTypes.func.isRequired,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
    /**
     * The loader component to use when the payment or drop-in is loading.
     */
    loader: PropTypes.node,
    /**
     * Whether we want to check by default the checkbox to save the payment method.
     */
    savePaymentMethod: PropTypes.bool,
    /**
     * The list of saved payment methods available.
     * The goal is to be able to select one of them and avoid typing it again.
     */
    savedPaymentMethods: PropTypes.array,
    /**
     * A warning message to display for payment at the card input step (mainly to advise the user to save its payment method).
     */
    paymentCardInputWarningMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * The operation type to process.
     */
    operation: PropTypes.oneOf(['credit', 'payment', 'refund']),
    /**
     * The theme to use (dark or light).
     */
    themeMode: PropTypes.oneOf(['light', 'dark']),
    /**
     * A function to call when we have a payment Error, like payment decline
     * payment aborted, or anything that can happen after you put in you credit
     * card number to make the transaction fail while you really want to buy
     * this concert ticket
     */
    onPaymentError: PropTypes.func,
};

PaymentsOS.defaultProps = {
    config: {},
    expiryAsText: false,
    hidePayButton: false,
    labelPayButton: null,  // Fallback on 'pay' or 'ask refund' label.
    loader: null,
    savePaymentMethod: false,
    savedPaymentMethods: [],
    paymentCardInputWarningMessage: null,
    operation: null,
    themeMode: 'light',
    onPaymentError: () => {},
};

PaymentsOS.displayName = 'PaymentsOS';

export default PaymentsOS;
