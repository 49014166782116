/**
 * This file gather all the utils function related to bank / credit cards.
 */

export const keepOnlyDigits = (value) => {
    return value.replace(/[\D]+/g, '');
};

export const formatCreditCardNumber = (value) => {
    let matches = value.match(/\d{4,19}/g);
    let match = (matches && matches.join('')) || '';
    let parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
        return parts.join(' ');
    } else {
        return value;
    }
};

export const findCardType = (cardNumber, cardTypes) => {
    for (const cardType of cardTypes) {
        for (const prefix of cardType.iin_prefixes) {
            if (cardNumber.indexOf(prefix) === 0) {
                return cardType;
            }
        }
    }
    return null;
};

export const findTokenizer = (cardType, tokenizers, savedPaymentMethodUuid=undefined) => {
    for (const tokenizer of tokenizers) {
        if (savedPaymentMethodUuid) {
            if (tokenizer.uuid === savedPaymentMethodUuid && tokenizer.card_types.indexOf(cardType) >= 0) {
                return tokenizer;
            }
        } else {
            if (tokenizer.card_types.indexOf(cardType) >= 0) {
                return tokenizer;
            }
        }
    }
    return null;
};

export const luhnValidate = (cardNumber) => {
    const length = cardNumber.length;
    let odd = false,
        total = 0,
        resOdd,
        resEven;
    for (let i = length; i > 0; i--) {
        resOdd = parseInt(cardNumber.charAt(i - 1));
        if (!odd) {
            total += resOdd;
        } else {
            resEven = resOdd * 2;

            switch (resEven) {
                case 10:
                    resEven = 1;
                    break;
                case 12:
                    resEven = 3;
                    break;
                case 14:
                    resEven = 5;
                    break;
                case 16:
                    resEven = 7;
                    break;
                case 18:
                    resEven = 9;
                    break;
                default:
                    break;
            }
            total += resEven;
        }
        odd = !odd;
    }

    return total !== 0 && total % 10 === 0;
};

/**
 * This function is to parse an ISO string date to an expiry date according to the MM/YY format.
 * @param {string} expiryDate The ISO string date.
 * @return {string} The converted expiry date converted into the format MM/YY.
 */
export const formatExpiryDate = (expiryDate) => {
    const expiryDateSplit = expiryDate?.split('-');
    if (!expiryDateSplit || expiryDateSplit.length < 2) {
        return expiryDate;
    }
    return `${expiryDateSplit[1]}/${expiryDateSplit[0]?.substring(2, 4)}`;
};
