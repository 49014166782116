import React from 'react';
import axios from 'axios';

export const useFormSave = (
    url,
    token,
    paymentRequestId,
    pspId,
    handleSuccess,
    handleError,
) => {
    return React.useCallback((data) => {
        axios({
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                data: data,
                request_token: token,
                payment_request_id: paymentRequestId,
                psp: pspId,
            },
        })
            .then(({data}) => {
                handleSuccess(data.data);
            })
            .catch((error) => {
                if (error.response?.status === 400) {
                    handleError(error.response.data);
                } else {
                    handleError({ code: 'internal' });
                    console.error(error.response);
                }
            });
    },
    [
        url,
        token,
        paymentRequestId,
        pspId,
        handleSuccess,
        handleError,
    ]);

};
