import React from 'react';

import PropTypes from 'prop-types';

import styles from './PaymentMethodOptionExpress.module.scss';
import { Trans } from 'react-i18next';

/**
 * This component is a payment express method container to display in the main component.
 * It will display all payment method 'on click' we want (basically Apple Pay and Google Pay).
 */
const PaymentMethodOptionExpress = ({
    paymentMethodComponents,
    shouldDisplayExpressCheckoutTitle,
}) => {
    return (
        <div id="payment_method_option_express">
            {shouldDisplayExpressCheckoutTitle && (
                <div className={styles.label}>
                    <Trans i18nKey="expressCheckout"/>
                </div>
            )}
            <div className={styles.component_box}>

                {paymentMethodComponents && paymentMethodComponents.map((paymentMethodComponent, index) => (
                    <div key={index} className={styles.component}>
                        {paymentMethodComponent}
                    </div>
                ))}
            </div>
        </div>
    );
};

PaymentMethodOptionExpress.propTypes = {
    /**
     * The main business components to display inside the container.
     * This is the components related to the payment methods the user could select to continue the process.
     */
    paymentMethodComponents: PropTypes.arrayOf(PropTypes.element),
    /**
     * Whether we want to display the title about express payment method ('express checkout') above the buttons.
     */
    shouldDisplayExpressCheckoutTitle: PropTypes.bool,
};

PaymentMethodOptionExpress.defaultProps = {
    paymentMethodComponents: [],
};

export default PaymentMethodOptionExpress;
