import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from 'lib/components/Icon';
import styles from './ErrorBlock.module.scss';

/**
 * This component is used to display a clean error message that can be readable by users.
 * It's mainly used for global errors not related to a particular form field and to display at the top op the window.
 */
const ErrorBlock = ({ errorCode }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.error_block}>
            <div className={styles.icon}>
                <Icon id="alert" />
            </div>
            <div className={styles.content}>
                {t([`error_${errorCode}`, 'error_PSPError'])}
            </div>
        </div>
    );
};

ErrorBlock.propTypes = {
    /**
     * The error code to translate in a readable human message
     * */
    errorCode: PropTypes.string,
};

ErrorBlock.defaultProps = {
    errorCode: null,
};

ErrorBlock.displayName = 'ErrorBlock';

export default ErrorBlock;
