import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'lib/components/Icon';
import Tooltip from 'lib/components/Tooltip';

import styles from './FormField.module.scss';

/**
 * This component is used as a common form field that can be used in any classical form.
 * For now, it does not provide a way to customize the data validation functions and the type of data allowed.
 */
const FormField = ({
    id,
    name,
    type,
    value,
    checked,
    options,
    label,
    required,
    disabled,
    placeholder,
    autoComplete,
    pattern,
    inputProps,
    onChange,
    onBlur,
    onFocus,
    formatAndValidate,
    TooltipProps,
    headerEndComponent,
    errors,
    errorIcon,
    shouldDisplayAsteriskIfRequired = true,
}) => {
    const [inputValue, setInputValue] = React.useState(value);
    const [elementCaretPosition, setElementCaretPosition] = React.useState([null, null]);
    const isNotValid = errors && errors.length !== 0;

    React.useEffect(
        () => {
            setInputValue(formatAndValidate ? formatAndValidate(value) : value);
        },
        [value, formatAndValidate],
    );

    React.useEffect(
        () => {
            // https://stackoverflow.com/questions/512528/set-keyboard-caret-position-in-html-textbox
            const element = elementCaretPosition[0];
            const caretPosition = elementCaretPosition[1];
            if (element && (caretPosition || caretPosition === 0)) {
                // eslint-disable-next-line
                element.value = element.value;
                if (element.createTextRange) {
                    let range = element.createTextRange();
                    range.move('character', caretPosition);
                    range.select();
                } else {
                    if (element.selectionStart || element.selectionStart === 0) {
                        element.focus();
                        element.setSelectionRange(caretPosition, caretPosition);
                    } else {
                        element.focus();
                    }
                }
            }
        },
        [elementCaretPosition],
    );

    const headerComponent = React.useMemo(
        () => (
            <div className={styles.header}>
                <div className={styles.left_wrapper}>
                    <div>
                        <label htmlFor={id || name}>
                            {label}
                        </label>
                    </div>
                    {shouldDisplayAsteriskIfRequired && required && (
                        <div className={styles.asterisk}>*</div>
                    )}
                    {TooltipProps?.children && (
                        <div className={styles.tooltip}>
                            <Tooltip
                                alt={TooltipProps.alt}
                                iconClass={TooltipProps.iconClass}
                            >
                                {TooltipProps.children}
                            </Tooltip>
                        </div>
                    )}
                </div>
                {headerEndComponent && (
                    <div className={styles.right_wrapper}>
                        <div className={styles.component}>
                            {headerEndComponent}
                        </div>
                    </div>
                )}
            </div>
        ),
        [TooltipProps, headerEndComponent, id, name, label, shouldDisplayAsteriskIfRequired, required],
    );

    const errorsComponent = React.useMemo(
        () => (
            <div className={styles.error}>
                {errors && errors.map((error, index) => (
                    <div
                        key={index}
                        className={styles.error_item}
                    >
                        <div className={styles.error_item_icon}>
                            {errorIcon === 'alert' && (<Icon id="alert" />)}
                            {errorIcon === 'cross' && (<Icon id="cross" />)}
                        </div>
                        <div className={styles.error_item_label}>
                            {error}
                        </div>
                    </div>
                ))}
            </div>
        ),
        [errors, errorIcon],
    );

    const onChangeInput = React.useCallback(
        (event) => {
            let caretPosition;
            let value;
            if (type === 'checkbox' || type === 'radio') {
                value = event.target.checked;
                caretPosition = null;
            } else {
                value = event.target.value;
                caretPosition = event.target.selectionStart;
            }
            let newValue = value;

            if (formatAndValidate) {
                const inputLength = value?.length || 0;
                newValue = formatAndValidate(value);
                caretPosition = caretPosition + ((newValue?.length || 0) - inputLength);
            }

            setInputValue(newValue);

            if (onChange) {
                onChange(event, newValue);
            }
            if (caretPosition || caretPosition === 0) {
                setElementCaretPosition([document.getElementById(event.target.id), caretPosition]);
            }
        },
        [onChange, formatAndValidate, type],
    );

    let classNameFormField = styles.field;
    let classNameFormFieldInput = styles.field_input;
    if (isNotValid) {
        classNameFormField = styles.field_error;
        classNameFormFieldInput = styles.field_input_error;
    }

    if (type === 'checkbox' || type === 'radio') {
        classNameFormFieldInput = styles.field_input_checkbox;
        if (isNotValid) {
            classNameFormFieldInput = styles.field_input_checkbox_error;
        }

        return (
            <div className={classNameFormField}>
                <div className={classNameFormFieldInput}>
                    <input
                        id={id || name}
                        name={name}
                        type={type}
                        value={inputValue}
                        checked={checked}
                        placeholder={placeholder}
                        autoComplete={autoComplete}
                        pattern={pattern}
                        required={required}
                        aria-required={required}
                        disabled={disabled}
                        aria-invalid={isNotValid}
                        onChange={onChangeInput}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        {...inputProps}
                    />
                    <div className={styles.field_input_label_radio_checkbox}>
                        <label htmlFor={id || name}>
                            {label}
                        </label>
                        {shouldDisplayAsteriskIfRequired && required && (
                            <span className={styles.checkbox_asterisk}>*</span>
                        )}
                    </div>
                </div>
                {isNotValid && errorsComponent}
            </div>
        );
    }

    if (type === 'select') {
        classNameFormFieldInput = styles.field_input_select;
        if (isNotValid) {
            classNameFormFieldInput = styles.field_input_select_error;
        }

        return (
            <div className={classNameFormField}>
                {headerComponent}
                <div className={classNameFormFieldInput}>
                    <select
                        id={id || name}
                        name={name}
                        onChange={onChangeInput}
                        disabled={disabled}
                        aria-invalid={isNotValid}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        defaultValue={inputValue}
                        {...inputProps}
                    >
                        {options?.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                {isNotValid && errorsComponent}
            </div>
        );
    }

    return (
        <div className={classNameFormField}>
            {headerComponent}
            <div className={classNameFormFieldInput}>
                <input
                    id={id || name}
                    name={name}
                    type={type}
                    value={inputValue}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    pattern={pattern}
                    required={required}
                    aria-required={required}
                    disabled={disabled}
                    aria-invalid={isNotValid}
                    onChange={onChangeInput}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    {...inputProps}
                />
            </div>
            {isNotValid && errorsComponent}
        </div>
    );
};

FormField.propTypes = {
    /**
     * id props to pass to the input for accessibility
     */
    id: PropTypes.string,
    /**
     * The form field name.
     */
    name: PropTypes.string.isRequired,
    /**
     * The value of the input to put by default.
     */
    value: PropTypes.string,
    /**
     * The input checked value for radio type.
     */
    checked: PropTypes.bool,
    /**
     * The list of options if the input type is a "select"
     */
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    })),
    /**
     * The type of the input.
     */
    type: PropTypes.string,
    /**
     * The label to display related to the field.
     */
    label: PropTypes.string,
    /**
     * Whether the input must be required or not.
     */
    required: PropTypes.bool,
    /**
     * Whether the input must be disabled or not.
     */
    disabled: PropTypes.bool,
    /**
     * The placeholder to display in the input when it is empty.
     */
    placeholder: PropTypes.string,
    /**
     * The common key to use to autocomplete the input automatically by the browser.
     */
    autoComplete: PropTypes.string,
    /**
     * The pattern regexp to pass to the input to make validation and display appropriate keyboard.
     */
    pattern: PropTypes.string,
    /**
     * The callback function to trigger when the input is changing.
     */
    onChange: PropTypes.func,
    /**
     * The callback function to trigger when the input is blurred.
     */
    onBlur: PropTypes.func,
    /**
     * The callback function to trigger when the input is focused.
     */
    onFocus: PropTypes.func,
    /**
     * The format and validate function to apply on the input value.
     */
    formatAndValidate: PropTypes.func,
    /**
     * The rest of specific input props to pass to the input tag and that are not props of this component.
     */
    inputProps: PropTypes.object,
    /**
     * The content of the tooltip to display when we hover / click on the tooltip icon. If null, no tooltip displayed.
     */
    TooltipProps: PropTypes.shape(Tooltip.propTypes),
    /**
     * The component to display at the end of the header at the right side of the label.
     */
    headerEndComponent: PropTypes.node,
    /**
     * The list of errors to display related to the field.
     */
    errors: PropTypes.arrayOf(PropTypes.string),
    /**
     * The type of icon to display before the error label.
     */
    errorIcon: PropTypes.oneOf(['cross', 'alert']),
    /**
     * Whether we want to display an asterisk next to the label to notice the field is required or not.
     */
    shouldDisplayAsteriskIfRequired: PropTypes.bool,
};

FormField.defaultProps = {
    id: null,
    value: '',
    checked: false,
    options: [],
    type: 'text',
    label: null,
    required: false,
    disabled: false,
    placeholder: null,
    autoComplete: null,
    pattern: null,
    onChange: null,
    onBlur: null,
    onFocus: null,
    formatAndValidate: null,
    inputProps: {},
    TooltipProps: {},
    headerEndComponent: null,
    errors: [],
    errorIcon: 'alert',
    shouldDisplayAsteriskIfRequired: true,
};

FormField.displayName = 'FormField';

export default FormField;
