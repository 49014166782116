import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import DevIntegration from 'dev/components/DevIntegration';
import PaymentPage from 'payment/PaymentPage';
import i18n from 'lib/config/i18n';

import './main.scss';

/**
 * This is the entry point.
 * yarn start:payment-widget runs Dev Integration and a standalone Payment Page.
 * yarn start:lib runs a Dev Integration with the lib integrated in it.
 * Otherwise, LyriaPay is used as a library and so not as a running application.
 */
ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            {window.location.pathname === '/' ? (
                <DevIntegration />
            ) : (
                <PaymentPage />
            )}
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
