import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'lib/components/Icon';
import styles from './Tooltip.module.scss';

/**
 * A basic tooltip to use to let the user be able to get more information about something.
 * It displays an icon with an interrogation point that let the information (children) display on click.
 */
const Tooltip = ({ children, alt }) => {
    const [isShown, setIsShown] = React.useState(false);

    if (!children) {
        return null;
    }

    return (
        <div className={styles.tooltip}>
            <div className={styles.icon} onClick={() => setIsShown(!isShown)} alt={alt}>
                <Icon id='tooltip' />
            </div>
            <div
                className={styles.content}
                aria-hidden={isShown}
                role="tooltip"
                style={{ visibility: isShown ? 'visible' : 'hidden' }}
                onClick={() => setIsShown(!isShown)}
            >
                {children}
            </div>
        </div>
    );
};

Tooltip.propTypes = {
    /**
     * The component to include in this Tooltip component.
     * This what should be displayed when we hover or click on the tooltip icon.
     */
    children: PropTypes.node,
    /**
     * The html alt property to pass to the tooltip icon.
     */
    alt: PropTypes.string,
};

Tooltip.defaultProps = {
    children: null,
    alt: null,
};

export default Tooltip;
