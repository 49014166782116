/**
 * This file gather all the utils function related to currencies or prices.
 */

/**
 * Compute the price to display taking in account the currency code and the language
 * @param {number} amount The amount of the price (with a 100 factor). We will divide by 100 to get the real price.
 * @param {number} decimalPlaces The decimal places to display
 * @param {string} currencyCode The trigram of the currency, e.g "EUR"
 * @param {string} language The language
 * @return {string} The price formatted according to the locale and the currency
 */
export const intlPriceFormat = (amount, decimalPlaces, currencyCode, language) => {
    if ((!amount && amount !== 0) || Number.isNaN(parseFloat(amount)) || !currencyCode) {
        return null;
    }

    const amountWithCentsAsDecimals = (amount / 100).toFixed(2);

    return new Intl
        .NumberFormat(
            language || 'en-GB',
            {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: decimalPlaces || (decimalPlaces !== 0 && 2),
                maximumFractionDigits: decimalPlaces || (decimalPlaces !== 0 && 2),
            },
        )
        .format(amountWithCentsAsDecimals);
};
