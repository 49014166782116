import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import checkIcon from '../../assets/check.svg';
import styles from './CallbackPaymentBlock.module.scss';

const CallbackPaymentBlock = ({
    isPaymentSuccess,
    redirectURL,
    redirectOnPaymentResult,
}) => {

    const { t } = useTranslation();
    const displayRedirection = React.useMemo(() => typeof (redirectURL) === 'string', [redirectURL]);

    return (
        <div className={styles.container}>
            {isPaymentSuccess
                ?
                (
                    <>
                        <img src={checkIcon} alt='check icon' />
                        <div className={styles.title}>
                            {t('callbackValidatedPayment')}
                        </div>
                    </>
                )
                :
                (
                    <div>{t('callbackFailedPayment')}</div>
                )}
            {displayRedirection && (
                <>
                    <button className={styles.button} onClick={redirectOnPaymentResult}>{t('callbackCTA')}</button>
                    <p className={styles.footer}>{t('callbackFooter')}</p>
                </>
            )}
        </div>
    );
};

CallbackPaymentBlock.propTypes = {
    isPaymentSuccess: PropTypes.bool,
    redirectURL: PropTypes.string,
    redirectOnPaymentResult: PropTypes.func,
};

export default CallbackPaymentBlock;
