import React from 'react';

import PropTypes from 'prop-types';

import styles from './ButtonLink.module.scss';

/**
 * This component is a button link style with a transparent style and an optional icon.
 */
const ButtonLink = React.forwardRef(
    (
        {
            label,
            type,
            disabled,
            startIcon,
            onClick,
            ...props // eslint-disable-line no-unused-vars
        },
        //todo: check this function: ref is not used, which should be the point of a ForwardRef
        ref,  // eslint-disable-line no-unused-vars
    ) => {
        return (
            <div id="button_link">
                <button onClick={onClick} disabled={disabled} type={type}>
                    <div className={styles.button_link_content}>
                        {startIcon && (
                            <div>
                                {startIcon}
                            </div>
                        )}
                        <div>
                            {label}
                        </div>
                    </div>
                </button>
            </div>
        );
    },
);

ButtonLink.propTypes = {
    /**
     * The label to display on the button.
     */
    label: PropTypes.string,
    /**
     * The button type
     */
    type: PropTypes.string,
    /**
     * Whether the button should be disabled.
     */
    disabled: PropTypes.bool,
    /**
     * The icon at the left of the button label.
     */
    startIcon: PropTypes.any,
    /**
     * The callback function to trigger when we click on the button.
     */
    onClick: PropTypes.func,
    /**
     * All the other props that can be passed to the button html element and not explicitly given in the props
     */
    props: PropTypes.object,
};

ButtonLink.defaultProps = {
    label: null,
    type: null,
    disabled: false,
    startIcon: null,
    onClick: null,
};

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
