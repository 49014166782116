import React from 'react';

import PropTypes from 'prop-types';

import Loader from 'lib/components/Loader';
import styles from './WaitingBlock.module.scss';

/**
 * This component is used to display a loader with a message or a content under it.
 */
const WaitingBlock = ({ loader, children }) => {
    return (
        <div className={styles.waiting_block}>
            {loader && (<div>{loader}</div>)}
            <div className={styles.content}>{children}</div>
        </div>
    );
};

WaitingBlock.propTypes = {
    /**
     * The loader to use for the display. By default, we use the loader with the animated circles.
     */
    loader: PropTypes.node,
    /**
     * The content to display under the loader.
     */
    children: PropTypes.node.isRequired,
};

WaitingBlock.defaultProps = {
    loader: <Loader />,
};

WaitingBlock.displayName = 'WaitingBlock';

export default WaitingBlock;
