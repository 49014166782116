/**
 * This file gather all the utils function related to the browser.
 */

export const getClientCapabilities = () => {
    return {
        screen: {
            height: window.screen.height,
            width: window.screen.width,
            color_depth: window.screen.colorDepth,
        },
        java_enabled: window.navigator.javaEnabled(),
        timezone_offset: new Date().getTimezoneOffset(),
        is_in_iframe: window.location !== window.top.location,
        apple_pay: {
            can_make_payments: false,
            // window.ApplePaySession && window.ApplePaySession.canMakePayments()
            // ? true
            // : false
        },
    };
};

/**
 * This function take a color in hex, rgb or rgba format and return the components of it.
 * @param {string} color The color in hex, rgb or rgba format.
 * @return {(number|number)[]|*} An array with red, green, blue and opacity value for the given color.
 */
export const decomposeColor = (color) => {
    let red;
    let green;
    let blue;
    let opacity = 1;
    let cleanColor = color.replace(/\s+/g, '');

    if (cleanColor.charAt(0) === '#') {
        cleanColor = cleanColor.replace(/#/, '');
        if (cleanColor.length >= 6) {
            red = parseInt(cleanColor.substring(0, 2), 16);
            green = parseInt(cleanColor.substring(2, 4), 16);
            blue = parseInt(cleanColor.substring(4, 6), 16);
        } else if (cleanColor.length >= 3) {
            red = parseInt(cleanColor.substring(0, 1), 16) * 17;
            green = parseInt(cleanColor.substring(1, 2), 16) * 17;
            blue = parseInt(cleanColor.substring(2, 3), 16) * 17;
        }
    } else if (cleanColor.indexOf('rgba') !== -1) {
        cleanColor = cleanColor.replace('rgba', '');
        cleanColor = cleanColor.replace('(', '');
        cleanColor = cleanColor.replace(')', '');
        [red, green, blue, opacity] = cleanColor.split(',');
    } else if (cleanColor.indexOf('rgb') !== -1) {
        cleanColor = cleanColor.replace('rgb', '');
        cleanColor = cleanColor.replace('(', '');
        cleanColor = cleanColor.replace(')', '');
        [red, green, blue] = cleanColor.split(',');
    } else {
        return [0, 0, 0, 1];
    }
    return [red, green, blue, opacity];
};

/**
 * This function take a color a return a new color variant more dark or more light.
 * @param {string} color The color in hex, rgb or rgba format.
 * @param {number} coefficient The coefficient between -1 and 1. If negative that means lighten, else darken.
 * @return The color variant in rgba format, lighten (if coefficient negative) or darken (if coefficient positive).
 */
export const lighenOrDarkenColor = (color, coefficient= 0) => {
    let [red, green, blue, opacity] = decomposeColor(color);

    if (coefficient < 0) {
        red += (255 - red) * -coefficient;
        green += (255 - green) * -coefficient;
        blue += (255 - blue) * -coefficient;
    } else {
        red = red * (1 - coefficient);
        green = green * (1 - coefficient);
        blue = blue * (1 - coefficient);
    }
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
};

/**
 * This function take a background color and return the best text color to use to have a good contrast.
 * @param {string} backgroundColor The color in hex, rgb or rgba format.
 */
export const getContrastText = (backgroundColor) => {
    /* eslint-disable-next-line */
    let [red, green, blue, opacity] = decomposeColor(backgroundColor);
    let rgb = [red, green, blue];
    rgb = rgb.map((c) => c / 255).map((c) => c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4);
    const luminance = Number((0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]).toFixed(3));
    const luminanceWhite = 1;
    return (Math.max(luminanceWhite, luminance) + 0.05) / (Math.min(luminanceWhite, luminance) + 0.05) >= 3
        ? '#ffffff'
        : '#000000';
};
