import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormField from 'lib/components/FormField';
import CardNumberInput from './components/CardNumberInput';
import CvxInput from './components/CvxInput';
import ExpiryInput from './components/ExpiryInput';

import styles from './CardInput.module.scss';
/**
 * This component is used as the main component to handle bank card information.
 */
const CardInput = ({
    displaySavePaymentMethodCheckbox,
    onChangeSavePaymentMethod,
    defaultSavePaymentMethod,
    onChangeCardNumber,
    defaultCardNumber,
    configCardTypes,
    onChangeExpiryDate,
    defaultExpiryDate,
    onChangeCvx,
    defaultCvx,
    lengthCvx,
    disabled,
    forceDirty,
}) => {
    const { t } = useTranslation();

    const [savePaymentMethod, setSavePaymentMethod] = React.useState(defaultSavePaymentMethod);

    const onChangeSavePaymentMethodValue = React.useCallback(
        (event, newValue) => {
            if (onChangeSavePaymentMethod) {
                onChangeSavePaymentMethod(newValue);
            }
            setSavePaymentMethod(newValue);
        },
        [onChangeSavePaymentMethod],
    );

    return (
        <div className={styles.input}>
            <div className={styles.input_row}>
                <CardNumberInput
                    defaultCardNumber={defaultCardNumber}
                    disabled={disabled}
                    onChange={onChangeCardNumber}
                    configCardTypes={configCardTypes}
                    forceDirty={forceDirty}
                />
            </div>
            <div className={styles.input_row_column}>
                <div className={styles.input_row}>
                    <ExpiryInput
                        defaultExpiryDate={defaultExpiryDate}
                        disabled={disabled}
                        onChange={onChangeExpiryDate}
                        forceDirty={forceDirty}
                    />
                </div>
                <div className={styles.input_row}>
                    <CvxInput
                        defaultCvx={defaultCvx}
                        disabled={disabled}
                        onChange={onChangeCvx}
                        forceDirty={forceDirty}
                        length={lengthCvx}
                    />
                </div>
            </div>
            {displaySavePaymentMethodCheckbox && (
                <div className={styles.input_row}>
                    <FormField
                        name='savepaymentmethod'
                        label={t('savePaymentMethod')}
                        checked={savePaymentMethod}
                        onChange={onChangeSavePaymentMethodValue}
                        type='checkbox'
                    />
                </div>
            )
            }
        </div >
    );
};

CardInput.propTypes = {
    /**
     * Whether we have to display the checkbox to let the user choose if he wants to save his payment method.
     */
    displaySavePaymentMethodCheckbox: PropTypes.bool,
    /**
     * The callback to trigger when the user click on the checkbox to save his payment method.
     * It should handle the new boolean value.
     */
    onChangeSavePaymentMethod: PropTypes.func,
    /**
     * The default value of the checkbox about save payment method.
     */
    defaultSavePaymentMethod: PropTypes.bool,
    /**
     * The callback to trigger when the user change the card number input.
     */
    onChangeCardNumber: PropTypes.func,
    /**
     * The default value of the card number.
     */
    defaultCardNumber: PropTypes.string,
    /**
     * An array with the card types allowed and the config related to each type.
     */
    configCardTypes: PropTypes.arrayOf(PropTypes.object),
    /**
     * The callback to trigger when the user change the expiry date input.
     */
    onChangeExpiryDate: PropTypes.func,
    /**
     * The default value of the expiry date.
     * The format must be XX/XX following the regexp ^(0[1-9]|1[0-2])\/([0-9]{2})$
     */
    defaultExpiryDate: PropTypes.string,
    /**
     * The callback to trigger when the user change the cvx input.
     */
    onChangeCvx: PropTypes.func,
    /**
     * The default value of the CVX.
     */
    defaultCvx: PropTypes.string,
    /**
     * An array that contains the rules about the min length and the max length allowed for the Cvx input.
     */
    lengthCvx: PropTypes.array,
    /**
     * Whether the inputs must be disabled (just in read only) or not.
     */
    disabled: PropTypes.bool,
    /**
     * Whether we want to set the inputs to be dirty (that is to say as it has already been touched by the user).
     * That's mainly for error display purpose.
     */
    forceDirty: PropTypes.bool,
};

CardInput.defaultProps = {
    displaySavePaymentMethodCheckbox: false,
    onChangeSavePaymentMethod: null,
    defaultSavePaymentMethod: false,
    onChangeCardNumber: null,
    defaultCardNumber: '',
    configCardTypes: [],
    onChangeExpiryDate: null,
    defaultExpiryDate: '',
    onChangeCvx: null,
    defaultCvx: '',
    disabled: false,
    forceDirty: false,
    lengthCvx: [3, 4],
};

export default CardInput;
