import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'lib/components/Button';
import FormField from 'lib/components/FormField';
import { useFormSave } from 'lib/hooks/useFormSave';

import styles from 'lib/components/PspComposer/PspComposer.module.scss';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents an international bank form, only used to collect refund request data for now.
 */
const InternationalBank = React.forwardRef(
    (
        {
            pspId,
            token,
            paymentRequestId,
            lyriaUrl,
            hidePayButton,
            labelPayButton,
            onPaymentResult,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const formRef = React.useRef(null);
        const [formData, setFormData] = React.useState({
            firstName: '',
            lastName: '',
            bankName: '',
            accountNumber: '',
            swift: '',
            address: '',
            city: '',
            zipCode: '',
            region: '',
            country: '',
        });
        const [errors, setErrors] = React.useState({});

        const handleSuccess = React.useCallback(
            (response) => {
                onPaymentResult({ success: true, data: response });
            },
            [onPaymentResult],
        );

        const handleError = React.useCallback(
            (response) => {
                if (response?.data) {
                    const fieldErrors = {};
                    Object
                        .keys(response.data)
                        .forEach((key) => {
                            fieldErrors[key] = response.data[key] && response.data[key].map((error) => t([
                                `error_${error.code}`,
                                'error_form',
                            ]));
                        });
                    setErrors(fieldErrors);
                }
                onPaymentResult({
                    success: false,
                    data: response,
                });
            },
            [onPaymentResult, t],
        );

        const url = lyriaUrl + '/internationalbank/save';
        const saveForm = useFormSave(url, token, paymentRequestId, pspId, handleSuccess, handleError);

        const submitForm = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                const data = {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    bank_name: formData.bankName,
                    account_number: formData.accountNumber,
                    swift_code: formData.swift,
                    address: formData.address,
                    city: formData.city,
                    zipcode: formData.zipCode,
                    region: formData.region,
                    country: formData.country,
                };

                saveForm(data);
            },
            [formData, saveForm],
        );

        const onChangeFormField = React.useCallback(
            (fieldName) => (event, newValue) => {
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: newValue,
                }));

            },
            [],
        );

        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    return formRef.current.click();
                },
                canClickOnPay: Object.values(formData).every((value) => !!value),
            }),
            [formData],
        );

        return (
            <div id="wz-lyriapay__international-bank" className={styles.common_psp}>
                <form noValidate onSubmit={submitForm}>
                    <div className={styles.common_psp_form}>
                        <div className={styles.common_field_group}>
                            <FormField
                                name="first_name"
                                value={formData?.firstName}
                                onChange={onChangeFormField('firstName')}
                                label={t('international_bank_firstName')}
                                errors={errors.first_name}
                                required
                            />
                            <FormField
                                name="last_name"
                                value={formData?.lastName}
                                onChange={onChangeFormField('lastName')}
                                label={t('international_bank_lastName')}
                                errors={errors.last_name}
                                required
                            />
                        </div>
                        <FormField
                            name="bank_name"
                            value={formData?.bankName}
                            onChange={onChangeFormField('bankName')}
                            label={t('international_bank_bankName')}
                            errors={errors.bank_name}
                            required
                        />
                        <FormField
                            name="account_number"
                            value={formData?.accountNumber}
                            onChange={onChangeFormField('accountNumber')}
                            label={t('international_bank_accountNumber')}
                            errors={errors.account_number}
                            required
                        />
                        <FormField
                            name="swift_code"
                            value={formData?.swift}
                            onChange={onChangeFormField('swift')}
                            label={t('international_bank_swiftCode')}
                            errors={errors.swift_code}
                            required
                        />
                        <FormField
                            name="address"
                            value={formData?.address}
                            onChange={onChangeFormField('address')}
                            label={t('international_bank_address')}
                            errors={errors.address}
                            required
                        />
                        <div className={styles.common_field_group}>
                            <FormField
                                name="city"
                                value={formData?.city}
                                onChange={onChangeFormField('city')}
                                label={t('international_bank_city')}
                                errors={errors.city}
                                required
                            />
                            <FormField
                                name="zipcode"
                                value={formData?.zipCode}
                                onChange={onChangeFormField('zipCode')}
                                label={t('international_bank_zipcode')}
                                errors={errors.zipcode}
                                required
                            />
                        </div>
                        <div className={styles.common_field_group}>
                            <FormField
                                name="region"
                                value={formData?.region}
                                onChange={onChangeFormField('region')}
                                label={t('international_bank_region')}
                                errors={errors.region}
                                required
                            />
                            <FormField
                                name="country"
                                value={formData?.country}
                                onChange={onChangeFormField('country')}
                                label={t('international_bank_country')}
                                errors={errors.country}
                                required
                            />
                        </div>
                    </div>
                    {!hidePayButton && (
                        <div className={styles.common_psp_submit}>
                            <Button
                                disabled={Object.values(formData).some((value) => !value)}
                                label={labelPayButton || t('payButton')}
                                ref={formRef}
                                type='submit'
                            />
                        </div>
                    )}
                </form>
            </div>
        );
    },
);

InternationalBank.propTypes = {
    /**
     * The is of the PSP returned by the backend in order to be able to pass it when we submit the form data.
     */
    pspId: PropTypes.number.isRequired,
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string,
    /**
    * The paymentRequestID to use in order to be able to call the backend when we submit the data in the paymentPage.
    */
    paymentRequestId: PropTypes.string,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
};

InternationalBank.defaultProps = {
    hidePayButton: false,
    labelPayButton: null,  // Fallback on 'pay' or 'ask refund' label.
};

InternationalBank.displayName = 'InternationalBank';

export default InternationalBank;
