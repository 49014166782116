import PropTypes from 'prop-types';
import React from 'react';
import styles from './PayButton.module.scss';

const PayButton = React.forwardRef(
    (
        {
            paymentRequestId,
            hidePayButton,
            paymentPageURL,
            isLoading,
        },
        ref,
    ) => {
        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    if (paymentRequestId && !isLoading) {
                        redirectToPaymentPage();
                    }
                },
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [paymentRequestId, isLoading],
        );
        const redirectToPaymentPage = React.useCallback(() => {
            window.location.href = `${paymentPageURL}/${paymentRequestId}`;
        }, [paymentPageURL, paymentRequestId]);

        return (
            <>
                {!hidePayButton && (
                    <button
                        disabled={isLoading}
                        className={styles.pay_button}
                        onClick={redirectToPaymentPage}>
                        {isLoading ? (
                            <span className={styles.loader}></span>
                        ) : (
                            <span>Pay</span>
                        )}
                    </button>
                )}
            </>
        );
    },
);

PayButton.propTypes = {
    paymentRequestId: PropTypes.string,
    hidePayButton: PropTypes.bool,
    paymentPageURL: PropTypes.string,
    isLoading: PropTypes.bool,
};

PayButton.defaultProps={
    paymentPageURL: 'http://localhost:3000',
};

export default PayButton;
