import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'lib/components/Button';
import FormField from 'lib/components/FormField';
import { cleanObjectOnValues } from 'lib/utils/objectUtils';
import { useFormSave } from 'lib/hooks/useFormSave';

import styles from 'lib/components/PspComposer/PspComposer.module.scss';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents a form used for Peru the bank account number,
 * and some information related to Peru. It is only used to collect refund request data.
 */
const PeruvianForm = React.forwardRef(
    (
        {
            pspId,
            token,
            paymentRequestId,
            lyriaUrl,
            hidePayButton,
            labelPayButton,
            onPaymentResult,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const formRef = React.useRef(null);
        const [formData, setFormData] = React.useState({
            bankName: '',
            bankAccountType: '',
            bankAccountNumber: '',
            bankCode: '',
            digitalPaymentApp: '',
            documentNumber: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
        });
        const [errors, setErrors] = React.useState({});

        const handleSuccess = React.useCallback(
            (response) => {
                onPaymentResult({ success: true, data: response });
            },
            [onPaymentResult],
        );

        const handleError = React.useCallback(
            (response) => {
                if (response?.data) {
                    const fieldErrors = {};
                    Object
                        .keys(response.data)
                        .forEach((key) => {
                            fieldErrors[key] = response.data[key] && response.data[key].map((error) => t([
                                `peruvian_form_${key}_error_${error.code}`,
                                `error_${error.code}`,
                                'error_form',
                            ]));
                        });
                    setErrors(fieldErrors);
                }
                onPaymentResult({
                    success: false,
                    data: response,
                });
            },
            [onPaymentResult, t],
        );

        const url = lyriaUrl + '/peruvian-form/save';
        const saveForm = useFormSave(url, token, paymentRequestId, pspId, handleSuccess, handleError);

        const submitForm = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                const rawData = {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    bank_code: formData.bankCode,
                    bank_account_number: formData.bankAccountNumber,
                    document_number: formData.documentNumber,
                    bank_name: formData.bankName,
                    email: formData.email,
                    phone_number: formData.phoneNumber,
                    bank_account_type: formData.bankAccountType,
                    digital_payment_app: formData.digitalPaymentApp,
                };

                const data = cleanObjectOnValues(rawData, ['', null]);
                saveForm(data);
            },
            [formData, saveForm],
        );

        const onChangeFormField = React.useCallback(
            (fieldName) => (event, newValue) => {
                setFormData((prevState) => ({
                    ...prevState,
                    [fieldName]: newValue,
                }));
            },
            [],
        );

        const formatAndValidateDNI = React.useCallback(
            (value) => value?.substring(0, 12),
            [],
        );

        const formatAndValidateBankAccountNumber = React.useCallback(
            (value) => value?.substring(0, 18),
            [],
        );

        const formatAndValidateBankCode = React.useCallback(
            (value) => value?.substring(0, 20),
            [],
        );

        React.useImperativeHandle(
            ref,
            () => ({
                pay() {
                    return formRef.current.click();
                },
                canClickOnPay: Object.values(formData).every((value) => !!value),
            }),
            [formData],
        );

        const requiredFields = [
            'bankAccountType',
            'bankAccountNumber',
            'bankCode',
            'documentNumber',
            'email',
            'firstName',
            'lastName',
        ];
        const hasRequiredFieldNotFilled = Object
            .values(
                Object.fromEntries(
                    Object
                        .entries(formData)
                        .filter(([k, _v]) => requiredFields.includes(k)),
                ),
            )
            .some((value) => !value);

        return (
            <div id="wz-lyriapay__peruvian-form" className={styles.common_psp}>
                <form noValidate onSubmit={submitForm}>
                    <div className={styles.common_psp_form}>
                        <div className={styles.common_field_group}>
                            <FormField
                                name="first_name"
                                value={formData?.firstName}
                                onChange={onChangeFormField('firstName')}
                                label={t('peruvian_form_first_name')}
                                errors={errors.first_name}
                                required
                            />
                            <FormField
                                name="last_name"
                                value={formData?.lastName}
                                onChange={onChangeFormField('lastName')}
                                label={t('peruvian_form_last_name')}
                                errors={errors.last_name}
                                required
                            />
                        </div>
                        <FormField
                            name="email"
                            type="email"
                            value={formData?.email}
                            onChange={onChangeFormField('email')}
                            label={t('peruvian_form_email')}
                            errors={errors.email}
                            required
                        />
                        <FormField
                            name="document_number"
                            value={formData?.documentNumber}
                            onChange={onChangeFormField('documentNumber')}
                            label={t('peruvian_form_document_number')}
                            errors={errors.document_number}
                            formatAndValidate={formatAndValidateDNI}
                            required
                        />
                        <FormField
                            name="bank_name"
                            value={formData?.bankName}
                            onChange={onChangeFormField('bankName')}
                            label={t('peruvian_form_bank_name')}
                            errors={errors.bank_name}
                            type="select"
                            options={[
                                { value: '', label: '' },
                                { value: 'BCP', label: 'BCP' },
                                { value: 'BBVA Continental', label: 'BBVA Continental' },
                                { value: 'Interbank', label: 'Interbank' },
                                { value: 'Scotiabank', label: 'Scotiabank' },
                                { value: 'Banco de la Nación', label: 'Banco de la Nación' },
                            ]}
                        />
                        <FormField
                            name="bank_account_type"
                            value={formData?.bankAccountType}
                            onChange={onChangeFormField('bankAccountType')}
                            label={t('peruvian_form_bank_account_type')}
                            errors={errors.bank_account_type}
                            required
                            type="select"
                            options={[
                                { value: '', label: '' },
                                { value: 'Ahorros', label: 'Cuenta de Ahorros' },
                                { value: 'Corriente', label: 'Cuenta Corriente' },
                            ]}
                        />
                        <FormField
                            name="bank_account_number"
                            value={formData?.bankAccountNumber}
                            onChange={onChangeFormField('bankAccountNumber')}
                            label={t('peruvian_form_bank_account_number')}
                            errors={errors.bank_account_number}
                            formatAndValidate={formatAndValidateBankAccountNumber}
                            required
                        />
                        <FormField
                            name="bank_code"
                            value={formData?.bankCode}
                            onChange={onChangeFormField('bankCode')}
                            label={t('peruvian_form_bank_code')}
                            errors={errors.bank_code}
                            formatAndValidate={formatAndValidateBankCode}
                            required
                        />
                        <FormField
                            name="digital_payment_app"
                            value={formData?.digitalPaymentApp}
                            onChange={onChangeFormField('digitalPaymentApp')}
                            label={t('peruvian_form_digital_payment_app')}
                            errors={errors.digital_payment_app}
                            type="select"
                            options={[
                                { value: '', label: '' },
                                { value: 'Plin', label: 'Plin' },
                                { value: 'Yape', label: 'Yape' },
                            ]}
                        />
                        <FormField
                            name="phone_number"
                            value={formData?.phoneNumber}
                            onChange={onChangeFormField('phoneNumber')}
                            label={t('peruvian_form_phone_number')}
                            errors={errors.phone_number}
                        />

                    </div>
                    {!hidePayButton && (
                        <div className={styles.common_psp_submit}>
                            <Button
                                disabled={hasRequiredFieldNotFilled}
                                label={labelPayButton || t('payButton')}
                                ref={formRef}
                                type='submit'
                            />
                        </div>
                    )}
                </form>
            </div>
        );
    },
);

PeruvianForm.propTypes = {
    /**
     * The is of the PSP returned by the backend in order to be able to pass it when we submit the form data.
     */
    pspId: PropTypes.number.isRequired,
    /**
     * The token to use in order to be able to call the backend when we submit the form data.
     */
    token: PropTypes.string,
    /**
     * The paymentRequestID to use in order to be able to call the backend when we submit the data in the paymentPage.
     */
    paymentRequestId: PropTypes.string,
    /**
     * The backend url to use to pass the form data.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
};

PeruvianForm.defaultProps = {
    hidePayButton: false,
    labelPayButton: null,  // Fallback on 'pay' or 'ask refund' label.
};

PeruvianForm.displayName = 'PeruvianForm';

export default PeruvianForm;
