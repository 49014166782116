import React from 'react';

import PropTypes from 'prop-types';

const AddIcon = React.lazy(() => import('./components/AddIcon').then().catch());
const AlertIcon = React.lazy(() => import('./components/AlertIcon').then().catch());
const AmexCardExampleIcon = React.lazy(() => import('./components/AmexCardExampleIcon').then().catch());
const AmexIcon = React.lazy(() => import('./components/AmexIcon').then().catch());
const ApplePayIcon = React.lazy(() => import('./components/ApplePayIcon').then().catch());
const ArrowDownIcon = React.lazy(() => import('./components/ArrowDownIcon').then().catch());
const ArrowRightIcon = React.lazy(() => import('lib/components/Icon/components/ArrowRightIcon/ArrowRightIcon').then().catch());
const BancontactIcon = React.lazy(() => import('./components/BancontactIcon').then().catch());
const BankIcon = React.lazy(() => import('lib/components/Icon/components/BankIcon/BankIcon').then().catch());
const CardExampleIcon = React.lazy(() => import('lib/components/Icon/components/CardExampleIcon/CardExampleIcon').then().catch());
const CbIcon = React.lazy(() => import('./components/CbIcon').then().catch());
const CheckedIcon = React.lazy(() => import('lib/components/Icon/components/CheckedIcon/CheckedIcon').then().catch());
const CrossIcon = React.lazy(() => import('./components/CrossIcon').then().catch());
const DinerClubInternationalIcon = React.lazy(() => import('./components/DinerClubInternationalIcon').then().catch());
const DiscoverIcon = React.lazy(() => import('./components/DiscoverIcon').then().catch());
const GooglePayIcon = React.lazy(() => import('./components/GooglePayIcon').then().catch());
const InfoIcon = React.lazy(() => import('./components/InfoIcon').then().catch());
const KlarnaIcon = React.lazy(() => import('./components/KlarnaIcon').then().catch());
const MaestroIcon = React.lazy(() => import('./components/MaestroIcon').then().catch());
const MastercardIcon = React.lazy(() => import('./components/MastercardIcon').then().catch());
const MobilePayIcon = React.lazy(() => import('./components/MobilePayIcon').then().catch());
const PayPalIcon = React.lazy(() => import('./components/PayPalIcon').then().catch());
const PostFinanceIcon = React.lazy(() => import('./components/PostFinanceIcon').then().catch());
const SofortIcon = React.lazy(() => import('./components/SofortIcon').then().catch());
const TwintIcon = React.lazy(() => import('./components/TwintIcon').then().catch());
const TooltipIcon = React.lazy(() => import('./components/TooltipIcon').then().catch());
const VisaIcon = React.lazy(() => import('./components/VisaIcon').then().catch());

const MAPPING_ID_ICON = {
    add: AddIcon,
    alert: AlertIcon,
    Amex: AmexIcon,
    AMEX: AmexIcon,
    amexCardExampleCvv: AmexCardExampleIcon,
    ApplePay: ApplePayIcon,
    arrowDown: ArrowDownIcon,
    arrowRight: ArrowRightIcon,
    Bancontact: BancontactIcon,
    cardExampleCvv: CardExampleIcon,
    CB: CbIcon,
    checked: CheckedIcon,
    ChileanForm: BankIcon,
    Clabe: BankIcon,
    CLABE: BankIcon,
    ColombianForm: BankIcon,
    cross: CrossIcon,
    DinersClub: DinerClubInternationalIcon,
    Discover: DiscoverIcon,
    GooglePay: GooglePayIcon,
    Iban: BankIcon,
    IBAN: BankIcon,
    info: InfoIcon,
    InternationalBank: BankIcon,
    Klarna: KlarnaIcon,
    Maestro: MaestroIcon,
    MAESTRO: MaestroIcon,
    MasterCard: MastercardIcon,
    MASTERCARD: MastercardIcon,
    MobilePay: MobilePayIcon,
    Paypal: PayPalIcon,
    paypal: PayPalIcon,
    PeruvianForm: BankIcon,
    PostFinanceCard: PostFinanceIcon,
    PostFinanceEfinance: PostFinanceIcon,
    Sofort: SofortIcon,
    SouthAfricanForm: BankIcon,
    HongKongForm: BankIcon,
    TWINT: TwintIcon,
    tooltip: TooltipIcon,
    Visa: VisaIcon,
    VISA: VisaIcon,
};

/**
 * This component provides a way to render an icon (from the available list) asynchronously (lazy).
 */
const Icon = ({ id, themeMode, fallback, style, className }) => {
    const fallbackValue = fallback || <span />;

    if (!id) {
        return null;
    }

    return (
        <React.Suspense fallback={fallbackValue}>
            {Object.keys(MAPPING_ID_ICON).includes(id)
                ? React.createElement(MAPPING_ID_ICON[id], { style, className, themeMode })
                : fallback}
        </React.Suspense>
    );
};

Icon.propTypes = {
    /**
     * The id of the icon to display, must exist in the list of the available icons.
     */
    id: PropTypes.oneOf([
        'add',
        'alert',
        'Amex',
        'AMEX',
        'amexCardExampleCvv',
        'ApplePay',
        'arrowDown',
        'arrowRight',
        'Bancontact',
        'cardExampleCvv',
        'CB',
        'checked',
        'ChileanForm',
        'Clabe',
        'CLABE',
        'ColombianForm',
        'cross',
        'DinersClub',
        'Discover',
        'GooglePay',
        'Iban',
        'IBAN',
        'IDeal',
        'info',
        'InternationalBank',
        'Klarna',
        'Maestro',
        'MAESTRO',
        'MasterCard',
        'MASTERCARD',
        'MobilePay',
        'Paypal',
        'paypal',
        'PeruvianForm',
        'tooltip',
        'PostFinanceCard',
        'PostFinanceEfinance',
        'Sofort',
        'SouthAfricanForm',
        'TWINT',
        'Visa',
        'VISA',
        'HongKongForm',
    ]),
    /**
     * The theme mode to display the appropriate version of the icon (if exist).
     */
    themeMode: PropTypes.oneOf(['light', 'dark']),
    /**
     * The fallback, the component or the text to display when the icon is not yet loaded or failed to be loaded.
     */
    fallback: PropTypes.any,
    /**
     * The style to add to the icon wrapper.
     */
    style: PropTypes.object,
    /**
     * The class names to add to the icon wrapper.
     */
    className: PropTypes.string,
};

Icon.defaultProps = {
    themeMode: 'light',
    fallback: null,
    style: null,
    className: null,
};

export default Icon;
