import React from 'react';

import PropTypes from 'prop-types';

import Icon from 'lib/components/Icon';
import { formatExpiryDate } from 'lib/utils/cardUtils';
import styles from './SavedCardInput.module.scss';

/**
 * This component is used as the main component to handle bank card information.
 */
const SavedCardInput = ({
    uuid,
    selected,
    onClickRadio,
    maskedCardNumber,
    displayExpiryDate,
    expiryDate,
    cardType,
    content,
}) => {
    const [expiryDateValue, setExpiryDateValue] = React.useState(expiryDate);

    React.useEffect(
        () => {
            setExpiryDateValue(formatExpiryDate(expiryDate));
        },
        [expiryDate],
    );

    return (
        <div
            className={styles.input}
        >
            <div
                className={styles.header}
                onClick={() => onClickRadio(uuid)}
            >
                <div>
                    <div className={styles.logo}>
                        <Icon id={cardType} fallback={cardType} />
                    </div>
                </div>
                <div>
                    <div className={styles.masked_number}>
                        {maskedCardNumber?.replace(/X/g, '*')}
                    </div>
                </div>
                {displayExpiryDate && (
                    <div className={styles.header_3}>
                        <div className={styles.expiry}>
                            {expiryDateValue}
                        </div>
                    </div>
                )}
            </div>
            {selected && content && (
                <div className="wz-lyriapay__saved-card-input__content">
                    {content}
                </div>
            )}
        </div>
    );
};

SavedCardInput.propTypes = {
    /**
     * The uuid of the saved card (in order to pass to the radio button value).
     */
    uuid: PropTypes.string,
    /**
     * Whether we want to select the saved card (expanded).
     * Note the selection and so the radio button value is managed outside the component.
     */
    selected: PropTypes.bool,
    /**
     * The callback function to trigger when we click on the radio button.
     * It should handle the uuid.
     */
    onClickRadio: PropTypes.func,
    /**
     * The card masked number to display.
     */
    maskedCardNumber: PropTypes.string,
    /**
     * If we have to display the expiry date on the header or not.
     */
    displayExpiryDate: PropTypes.bool,
    /**
     * The value of the expiry date to display.
     * The format must be XX/XX following the regexp ^(0[1-9]|1[0-2])\/([0-9]{2})$
     */
    expiryDate: PropTypes.string,
    /**
     * The type of card.
     */
    cardType: PropTypes.string,
    /**
     * The content to display when we select ('selected' is true) the saved card.
     * Usually is to put the Cvx input.
     */
    content: PropTypes.node,
};

SavedCardInput.defaultProps = {
    uuid: null,
    selected: false,
    onClickRadio: null,
    maskedCardNumber: '',
    displayExpiryDate: true,
    expiryDate: '',
    cardType: '',
    content: null,
};

export default SavedCardInput;
