/**
 * This file is the entrypoint of the lib to export all the components
 * that must be available for people that use lyriapay library.
 */
import PspComposer from 'lib/components/PspComposer';
import React from 'react';
import PayButton from 'lib/components/PaymentPageButton';
import PropTypes from 'prop-types';
import axios from 'axios';


const LyriaPay = React.forwardRef(
    (
        {
            isPaymentPage,
            token,
            lyriaUrl,
            ...rest
        }, ref,
    ) => {
        const App = isPaymentPage ? PayButton : PspComposer;
        const [paymentRequestId, setPaymentRequestId] = React.useState();
        const [paymentPageURL, setPaymentPageURL] = React.useState();
        const [isLoading, setIsLoading] = React.useState(true);
        React.useEffect(() => {
            if (token && lyriaUrl) {
                axios({
                    method: 'POST',
                    url: `${lyriaUrl}/payments/paymentrequests`,
                    data: {
                        request_token: token,
                    },
                })
                    .then((paymentRequestData) => {
                        setPaymentPageURL(paymentRequestData.data.payment_page_url);
                        setPaymentRequestId(paymentRequestData.data.id);
                        setIsLoading(false);
                    });
            }
        }, [token, lyriaUrl, setIsLoading]);

        return (
            <App 
                ref={ref}
                token={token}
                lyriaUrl={lyriaUrl}
                paymentRequestId={paymentRequestId}
                paymentPageURL={paymentPageURL}
                isLoading={isLoading}
                {...rest}
            />
        );
    });

LyriaPay.propTypes = {
    isPaymentPage: PropTypes.bool,
    token: PropTypes.string.isRequired,
    lyriaUrl: PropTypes.string.isRequired,
};
LyriaPay.defaultProps={
    isPaymentPage:false,
};

export default LyriaPay;
