import React, { useRef } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'lib/components/Button';
import Icon from 'lib/components/Icon';
import Loader from 'lib/components/Loader';
import styles from './IngenicoLegacy.module.scss';
import styles_psp from 'lib/components/PspComposer/PspComposer.module.scss';
import IngenicoLegacyForm from './IngenicoLegacyForm';

/**
 * This component is one of the multiple available payment method in this project.
 * It represents the Ingenico PSP integration that can deal with multiple bank card types.
 */
const IngenicoLegacy = React.forwardRef(
    (
        {
            config,
            token,
            paymentRequestId,
            lyriaUrl,
            hidePayButton, // eslint-disable-line no-unused-vars
            labelPayButton,
            paymentMethods, // eslint-disable-line no-unused-vars
            onPaymentResult,
            themeMode,
        },
        //todo: check this function: ref is not used, which should be the point of a ForwardRef
        ref,  // eslint-disable-line no-unused-vars
    ) => {
        /* ------------ Initialization of the imports, the variables and the states ------------ */

        // Hooks
        const { t } = useTranslation();

        const [isReady, setIsReady] = React.useState(true);
        const [payload, setPayload] = React.useState({});
        const formEl = useRef();
        /* ------------ The main functions ------------ */

        // The api call to Lyria (backend) to trigger Ingenico payment process.
        const getHostedInfo = React.useCallback(
            () => {
                axios({
                    method: 'post',
                    url: lyriaUrl + '/ingenico_legacy/process',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        payment_request_id: paymentRequestId,
                        request_token: token,
                        psp: config.id,
                        lyria_url: lyriaUrl,
                    },
                })
                    .then((res) => {
                        setIsReady(true);
                        setPayload(res.data.payload);
                        formEl.current && formEl.current.submit();
                    })
                    .catch((error) => {
                        if (onPaymentResult) {
                            onPaymentResult({ success: false, data: { code: 'internal' } });
                        }
                        console.info(error);
                    });
            },
            [config.id, onPaymentResult, token, paymentRequestId, lyriaUrl],
        );

        /* ------------ The JSX to return according to the logic above ------------ */
        if (!isReady) {
            return (
                <div id="ingenico_legacy">
                    <Loader />
                </div>
            );
        }

        return (
            <div id="ingenico_legacy">
                <div className={styles.label}>
                    {t('ingenico_label_checkout', { label: labelPayButton || t('payButton') })}
                </div>
                <div className={styles_psp.common_psp_submit}>
                    <Button
                        onClick={() => {
                            setIsReady(true);
                            return getHostedInfo();
                        }}
                        label={labelPayButton || t('payButton')}
                        startIcon={<Icon id="add" themeMode={themeMode} />}
                    />
                </div>
                <IngenicoLegacyForm
                    payload={payload}
                    formEl={formEl}
                    config={config}
                />
            </div>
        );
    },
);

IngenicoLegacy.propTypes = {
    /**
     * The specific configuration related to this payment method (IBAN).
     */
    config: PropTypes.object,
    /**
     * The token to pass to call the back-end.
     */
    token: PropTypes.string,
    /**
     * The paymentRequestID to use in order to be able to call the backend when we submit the data in the paymentPage.
     */
    paymentRequestId: PropTypes.string,
    /**
     * The url of lyria, the payment back-end.
     */
    lyriaUrl: PropTypes.string.isRequired,
    /**
     * Whether the submit button must be displayed or not.
     */
    hidePayButton: PropTypes.bool,
    /**
     * The label of the 'pay' button to display. hidePayButton props must be false of course.
     */
    labelPayButton: PropTypes.string,
    /**
     * The list of payment methods that belongs to the given payment method. That's a kind of sub payment method.
     * For example, for 'credit card' payment method we could have Visa, Mastercard and so on.
     */
    paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            payment_method: PropTypes.string.isRequired,
        }),
    ),
    /**
     * The callback function to trigger when the backend return the result of the form data submit.
     */
    onPaymentResult: PropTypes.func.isRequired,
    /**
     * The theme to use (dark or light).
     */
    themeMode: PropTypes.oneOf(['light', 'dark']),
};

IngenicoLegacy.defaultProps = {
    config: {},
    hidePayButton: false,
    paymentMethods: [],
    onPaymentResult: null,
    themeMode: 'light',
};

IngenicoLegacy.displayName = 'IngenicoLegacy';

export default IngenicoLegacy;
