export const bankCodeMap = {
    '003': 'STANDARD CHARTERED BANK (HONG KONG) LIMITED',
    '004': 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (HSBC)',
    '005': 'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK',
    '006': 'CITIBANK, N.A.',
    '007': 'JPMORGAN CHASE BANK, N.A.',
    '008': 'NATWEST MARTETS PLC',
    '009': 'CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED',
    '012': 'BANK OF CHINA (HONG KONG) LIMITED',
    '015': 'THE BANK OF EAST ASIA, LIMITED',
    '016': 'DBS BANK (HONG KONG) LIMITED',
    '018': 'CHINA CITIC BANK INTERNATIONAL LIMITED',
    '020': 'CMB WING LUNG BANK LIMITED.',
    '022': 'OVERSEA-CHINESE BANKING CORPORATION LTD.',
    '024': 'HANG SENG BANK LTD.',
    '025': 'SHANGHAI COMMERCIAL BANK LTD.',
    '027': 'BANK OF COMMUNICATIONS CO., LTD',
    '028': 'PUBLIC BANK (HONG KONG) LIMITED',
    '035': 'OCBC WING HANG BANK LIMITED',
    '038': 'TAI YAU BANK LTD.',
    '039': 'CHIYU BANKING CORPORATION LTD.',
    '040': 'DAH SING BANK, LTD.',
    '041': 'CHONG HING BANK LIMITED',
    '043': 'NANYANG COMMERCIAL BANK, LTD.',
    '045': 'UCO BANK',
    '046': 'KEB HANA BANK',
    '047': 'MUFG BANK, LTD.',
    '049': 'BANGKOK BANK PUBLIC COMPANY LIMITED',
    '050': 'INDIAN OVERSEAS BANK',
    '052': 'DBS BANK LTD.',
    '054': 'DEUTSCHE BANK AG',
    '055': 'BANK OF AMERICA, N.A.',
    '056': 'BNP PARIBAS',
    '058': 'BANK OF INDIA',
    '060': 'NATIONAL BANK OF PAKISTAN',
    '061': 'TAI SANG BANK LIMITED',
    '063': 'MALAYAN BANKING BERHAD (MAYBANK)',
    '065': 'SUMITOMO MITSUI BANKING CORPORATION',
    '066': 'PT. BANK NEGARA INDONESIA (PERSERO) TBK.',
    '067': 'BDO UNIBANK, INC.',
    '071': 'UNITED OVERSEAS BANK LTD.',
    '072': 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LIMITED',
    '074': 'BARCLAYS BANK PLC.',
    '076': 'THE BANK OF NOVA SCOTIA',
    '080': 'ROYAL BANK OF CANADA',
    '081': 'SOCIETE GENERALE',
    '082': 'STATE BANK OF INDIA',
    '085': 'THE TORONTO-DOMINION BANK',
    '086': '086 BANK OF MONTREAL',
    '092': 'CANADIAN IMPERIAL BANK OF COMMERCE',
    '097': 'COMMERZBANK AG',
    '103': 'UBS AG, HONG KONG',
    '106': 'HSBC BANK USA, N.A.',
    '109': 'MIZUHO BANK, LTD., HONG KONG BRANCH',
    '113': 'DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK',
    '118': 'WOORI BANK',
    '119': 'PHILIPPINE NATIONAL BANK',
    '128': 'FUBON BANK (HONG KONG) LIMITED',
    '138': 'MITSUBISHI UFJ TRUST AND BANKING CORPORATION',
    '139': 'THE BANK OF NEW YORK MELLON',
    '145': 'ING BANK N.V.',
    '147': 'BANCO BILBAO VIZCAYA ARGENTARIA S.A.',
    '150': 'NATIONAL AUSTRALIA BANK, LIMITED',
    '151': 'WESTPAC BANKING CORPORATION',
    '152': 'AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED',
    '153': 'COMMONWEALTH BANK OF AUSTRALIA',
    '161': 'INTESA SANPAOLO S.P.A.',
    '164': 'UNICREDIT BANK AG',
    '165': 'SVENSKA HANDELSBANKEN AB (PUBL)',
    '170': 'THE CHIBA BANK LTD.',
    '178': 'KBC BANK N.V., HONG KONG BRANCH',
    '180': 'WELLS FARGO BANK, N.A., HONG KONG BRANCH',
    '183': 'COÖPERATIEVE RABOBANK U.A.',
    '185': 'DBS BANK LTD., HONG KONG BRANCH',
    '186': 'THE SHIZUOKA BANK LTD.',
    '188': 'THE HACHIJUNI BANK, LTD.',
    '198': 'HUA NAN COMMERCIAL BANK, LTD.',
    '199': 'THE SHIGA BANK, LTD.',
    '201': 'BANK OF TAIWAN',
    '202': 'THE CHUGOKU BANK LIMITED',
    '203': 'FIRST COMMERCIAL BANK',
    '205': 'COUTTS & CO. LTD.',
    '206': 'CHANG HWA COMMERCIAL BANK, LTD.',
    '210': 'NATIXIS',
    '214': 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED',
    '220': 'STATE STREET BANK AND TRUST COMPANY',
    '221': 'CHINA CONSTRUCTION BANK CORPORATION',
    '222': 'AGRICULTURAL BANK OF CHINA LIMITED',
    '227': 'ERSTE GROUP BANK AG',
    '229': 'CTBC BANK CO., LTD.',
    '230': 'TAIWAN BUSINESS BANK, LTD.',
    '233': 'TAIWAN BUSINESS BANK, LTD.',
    '235': 'HSBC PRIVATE BANK (SUISSE) SA',
    '236': 'CATHAY UNITED BANK COMPANY, LIMITED',
    '237': 'EFG BANK AG',
    '238': 'CHINA MERCHANTS BANK CO., LTD.',
    '239': 'TAIPEI FUBON COMMERCIAL BANK CO., LTD.',
    '241': 'BANK SINOPAC',
    '242': 'MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD.',
    '243': 'E.SUN COMMERCIAL BANK, LTD.',
    '245': 'TAISHIN INTERNATIONAL BANK CO., LTD.',
    '248': 'HONG LEONG BANK BERHAD',
    '249': 'STANDARD CHARTERED BANK HONG KONG BRANCH',
    '250': 'CITIBANK (HONG KONG) LIMITED',
    '251': 'ICICI BANK LIMITED',
    '254': 'MELLI BANK PLC',
    '258': 'EAST WEST BANK',
    '259': 'BANK OF BARODA',
    '260': 'FAR EASTERN INTERNATIONAL BANK',
    '261': 'AXIS BANK LIMITED',
    '262': 'CANARA BANK',
    '263': 'CATHAY BANK',
    '264': 'LAND BANK OF TAIWAN CO., LTD.',
    '265': 'TAIWAN COOPERATIVE BANK, LTD.',
    '266': 'PUNJAB NATIONAL BANK',
    '267': 'BANCO SANTANDER S.A.',
    '268': 'UNION BANK OF INDIA',
    '269': 'UNION BANK OF INDIA',
    '271': 'INDUSTRIAL BANK OF KOREA',
    '272': 'BANK OF SINGAPORE LIMITED',
    '273': 'SHINHAN BANK',
    '274': 'O-BANK CO., LTD',
    '275': 'BNP PARIBAS SECURITIES SERVICES',
    '276': 'CHINA DEVELOPMENT BANK',
    '277': 'FIRST ABU DHABI BANK PJSC',
    '278': 'BANK J. SAFRA SARASIN AG',
    '307': 'ABN AMRO BANK N.V.',
    '308': 'HDFC BANK LIMITED',
    '309': 'UNION BANCAIRE PRIVEE, UBP SA',
    '316': 'SKANDINAVISKA ENSKILDA BANKEN AB',
    '320': 'BANK JULIUS BAER & CO. LTD.',
    '324': 'CREDIT INDUSTRIEL ET COMMERCIAL',
    '337': 'TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD.',
    '338': 'BANK OF CHINA LIMITED, HONG KONG BRANCH',
    '339': 'CA INDOSUEZ (SWITZERLAND) SA',
    '341': 'ICBC STANDARD BANK PLC',
    '342': 'LGT BANK AG',
    '344': 'MACQUARIE BANK LIMITED',
    '345': 'SHANGHAI PUDONG DEVELOPMENT BANK CO., LTD.',
    '353': 'CHINA MINSHENG BANKING CORP., LTD.',
    '357': 'PICTET & CIE (EUROPE) S.A.',
    '360': 'NATWEST MARKETS N.V.',
    '368': 'CHINA EVERBRIGHT BANK CO., LTD.',
    '371': 'SUMITOMO MITSUI TRUST BANK, LIMITED',
    '372': 'BANK OF SHANGHAI (HONG KONG) LIMITED',
    '374': 'CIMB BANK BERHAD',
    '377': 'INDUSTRIAL BANK CO., LTD',
    '378': 'YUANTA COMMERCIAL BANK CO., LTD.',
    '379': 'MASHREQ BANK - PUBLIC SHAREHOLDING COMPANY',
    '381': 'KOOKMIN BANK',
    '382': 'BANK OF COMMUNICATIONS (HONG KONG) LIMITED',
    '383': 'CHINA ZHESHANG BANK CO., LTD.',
    '384': 'MORGAN STANLEY BANK ASIA LIMITED',
    '385': 'PING AN BANK CO., LTD.',
    '386': 'HUA XIA BANK CO., LIMITED',
    '387': 'ZA BANK LIMITED',
    '388': 'LIVI VB LIMITED',
    '389': 'MOX BANK LIMITED',
    '390': 'WELAB BANK LIMITED',
    '391': 'FUSION BANK LIMITED',
    '392': 'PING AN ONECONNECT BANK (HONG KONG) LIMITED',
    '393': 'ANT BANK (HONG KONG) LIMITED',
    '395': 'AIRSTAR BANK LIMITED',
    '802': 'HONG KONG SECURITIES CLEARING COMPANY LIMITED',
    '868': 'CLS BANK INTERNATIONAL',
};
