import React from 'react';

import styles from './Loader.module.scss';

/**
 * The animated loader component to display when something is processing to let the user wait.
 */
const Loader = () => {
    return (
        <div className={styles.loader}>
            <span className={`${styles.circle} ${styles.circle_1}`}></span>
            <span className={`${styles.circle} ${styles.circle_2}`}></span>
            <span className={`${styles.circle} ${styles.circle_3}`}></span>
            <span className={`${styles.circle} ${styles.circle_4}`}></span>
        </div>
    );
};

Loader.displayName = 'Loader';

export default Loader;
